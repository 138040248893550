// pure css typing
.typing {
    width: 15ch;
    animation: typing 2s steps(22), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    // border-right: 3px solid;
    font-size: 2em;
}
  
@keyframes typing {
    from {
        width: 0
    }
}
    
@keyframes blink {
    50% {
        border-color: transparent
    }
}