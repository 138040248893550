//RWD
$rwd-1920: 1920px;
$rwd-1600: 1600px;
$rwd-1550: 1550px;
$rwd-1300: 1300px;
$rwd-1080: 1080px;
$rwd-1024: 1024px;
$rwd-810: 810px;
$rwd-640: 640px;
$rwd-500: 500px;
$rwd-414: 414px;
$rwd-380: 380px;
$rwd-320: 320px;
@mixin setMinRwd {
    @media (min-width: 1101px) {
        @content
    }
}

@mixin setMaxRwd {
    @media (max-width: 1100px) {
        @content
    }
}

@mixin setStyleRwd($size) {
    @media (max-width: $size) {
        @content
    }
}

@mixin setStyleMinRwd($size) {
    @media (min-width: $size) {
        @content
    }
}

@mixin setWidthRwd($size1, $size2) {
    @media (min-width: $size1) and (max-width: $size2) {
        @content
    }
}

@mixin setHightRwd($size1, $size2) {
    @media (min-height: $size1) and (max-height: $size2) {
        @content
    }
}

@mixin setWHRwd($size1, $size2) {
    @media (max-width: $size1) and (max-height: $size2) {
        @content
    }
}

@mixin pad1080 {
    @media (max-width: $rwd-1080) {
        @content
    }
}

@mixin pad1024 {
    @media (max-width: $rwd-1024) {
        @content
    }
}

@mixin pad {
    @media (max-width: $rwd-810) {
        @content
    }
}

@mixin mobile {
    @media (max-width: $rwd-640) {
        @content
    }
}

@mixin mobile414 {
    @media (max-width: $rwd-414) {
        @content
    }
}

@mixin mobileSmall {
    @media (max-width: $rwd-380) {
        @content
    }
}