// for Index swiper effect
$color_1: #fff0;
$font-family_1: var(--fancy-slider-font-family);

:root {
    --fancy-slider-theme-color: #fff;
    --fancy-slider-font-family: sans-serif;
    
    --fancy-slider-button-side-offset: 32px;
    --fancy-slider-button-mobile-side-offset: 12px;
    --fancy-slider-button-mobile-bottom-offset: 0px;

    --fancy-slider-head-font-size-s: 36px;
    --fancy-slider-head-font-size: 48px;
    --fancy-slider-head-font-size-b: 60px;
    --fancy-slider-title-font-size: 33px;
    
    --fancy-slider-title-font-family: $OpenSans-300;
    --fancy-slider-title-line-height: 1;

    /* Card */
    --card-transition-duration: 500ms;
    --card-transition-delay: 250ms;    /* delay background color */
    
    /* background */
    --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
    --fancy-slider-transition-background-delay: 1000ms;    /* delay background color */
    
    /* other */
    --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
    --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
    --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
    
    /* scaling */
    --fancy-slider-transform-scale-slide: 1;               /* before scale in slide - same as in js */
    --fancy-slider-transform-scale-img: 1;               /* before scale in img - same as in js */
    
    /* nav */
    --fancy-slider-transition-button: 400ms;        /*  */
    --fancy-slider-transition-button-arrow: 400ms;  /*  */
    --fancy-slider-transition-button-circle: 400ms; /*  */

    // Video
    --bs-aspect-ratio-mobile: 100%
}
    
@keyframes jump {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 10px;
    }
    100% {
        bottom: 0;
    }
}

@keyframes moveCircle {
    0%,
    100% {
        transform: translateY(-15%);
    }
    50% {
        transform: translateY(-5%);
    }
}
  
.animationItem {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
  
.animationItem.animated {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.index{
    // 內框
    &-content{
        padding-top: 2em;
        padding-bottom: 2em;

        @include setStyleRwd(1400px){
            padding-left: 1em;
            padding-right: 1em;
        }

        @include setMaxRwd{
            padding: 1.5em 0.5em;
        }
    }

    // 頁面使用
    &-page{
        &Wrapper{
            @extend %setFlex;
            @include setMaxRwd{
                width: 100%;
                flex-direction: column;
                justify-content: center;
            }
        }

        &Swiper{
            width: 100%;
            overflow: hidden;
            .swiper-wrapper{
                /* 通过改变animation-timing-function 制作弹性切换效果 */
                transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
            }

            // 共用
            .swiper-button-wrapper{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                top: -2em;
                .swiper-button-prev,
                .swiper-button-next{
                    @include setSize(47px,47px);
                    overflow: hidden;
                    
                    &::after {
                        display: none;
                    }

                    svg{
                        path{
                            fill: $colorMain;
                        }
                    }

                    &.swiper-button-disabled {
                        svg{
                            path{
                                fill: #bfbfbf;
                            }
                        }
                    }

                    @include setMinRwd{
                        position: static;
                    }
                }
            }
        }

        // 標題用
        &Head{
            
        }

        // 內文用
        &Article{
            line-height: 1.5;
        }

        &Undertake{
            background-color: #f5f5f5;
            padding: 0;
            z-index: 1;

            @include setWidthRwd(1301px,1710px){
                padding-top: 3em;
                padding-bottom: 4em;
            }

            @include setWidthRwd(1100px,1300px){
                padding-top: 4em;
                padding-bottom: 5em;
            }

            .index-pageWrapper{
                max-width: 1800px;
                margin-left: auto;

                @include setMinRwd{
                    padding-left: 1em;
                }

                @include setWidthRwd(1100px,1710px){
                    padding-left: 3em;
                }
            }

            .pageGrid-list-1to1{
                align-items: center;
                grid-template-columns: 1.6fr 1.15fr;
            }
        }
    }
}

// 文字特效
.svgText{
    text {
        --min-size: 72;
        --max-size: 140;
        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        text-transform: uppercase;
        fill: #fff;
        stroke: $colorMain;
        stroke-width: 1px;
        animation: animated-stroke 5s;
        text-anchor: middle;
        dominant-baseline: mathematical;
        font-family: $Poiret;

        &.textsmall{
            --min-size: 30;
            --max-size: 48;
            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            color: #a0a0a0;
            font-family: "Eternate";
        }
    }

    @include setMaxRwd{
        height: 6em;
        text { dominant-baseline: central; }
    }
}

.page{
    &About{
        .pageGrid{
            align-items: center;
            @include setWidthRwd(1100px,1600px){
                grid-template-columns: 0.6fr 1fr;
            }
        }

        .pageAbout{
            &-img{
                @include setStyleMinRwd(1600px){
                    width: get-vw(650px);
                }

                @include setMaxRwd{
                    max-width: 70%;
                    margin: auto;
                }
            }

            &-info{
                &-article{
                    .article{
                        --min-size: 17;
                        --max-size: 18;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                        line-height: 1.65;
                    }
                    
                    @include setMinRwd{
                        .article{
                            margin-top: 1em;
                            margin-bottom: 4em;
                            padding-left: 1em;
                        }
                    }
                }

                @include setMinRwd{
                    width: 100%;
                    padding-right: get-vw(30px);
                }

                @include setStyleMinRwd(1600px){
                    margin-left: get-vw(-30px);
                }

                @include setWidthRwd(1100px,1600px){
                    padding-left: get-vw(80px);
                    padding-right: get-vw(80px);
                }
            }
        }

        // .pageAbout-heading{
        //     color: #fff;
        //     -webkit-text-stroke-width: 1px;
        //     -webkit-text-stroke-color: $colorMain; /* Can be changed in the live sample */
        //     text-transform: uppercase;
        //     font-family: $Poiret;
            
        //     --min-size: 40;
        //     --max-size: 143;
        //     --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
        //     font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        // }

        @include setMinRwd{
            padding-top: get-vw(100px);
            margin-top: get-vw(-100px);
        }

        @include setStyleMinRwd(1600px){
            padding-bottom: get-vw(15px);
        }

        @include setWidthRwd(1100px,1600px){
            padding-left: 4em;
            padding-right: 4em;
        }

        @include setMaxRwd{
            .pageGrid{
                display: block;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    &Undertake{
        &-img{
            margin-top: 2em;
            img{
                width: 100%;
            }

            @include setMinRwd{
                margin-top: -4em;
            }

            @include setWidthRwd(1100px,1710px){
                margin-top: -6em;
            }
        }

        &-info{
            &-head{
                display: flex;
                align-items: flex-end;
                .img{
                    margin-right: get-vw(90px);
                    margin-left: get-vw(45px);

                    @include setStyleRwd(1500px){
                        max-width: 6em;
                    }

                    @include setMaxRwd{
                        max-width: 7em;
                    }
                }

                .txt{
                    color: $colorMain;
                    --min-size: 25;
                    --max-size: 32;
                    --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                    font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                    font-family: $fontNotoSerif-tc;
                    padding-bottom: get-vw(60px);
                }

                &-animate{
                    z-index: 3;
                }

                margin-bottom: -1.5rem;

                @include setMinRwd{
                    margin-top: -2.75rem;
                }
            }

            &-list{
                position: relative;
                padding-top: get-vw(30px);
                padding-bottom: get-vw(60px);
                padding-right: get-vw(60px);

                &Item{
                    position: relative;
                    padding-top: 1em;
                    cursor: pointer;

                    .Txt{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }

                    .Img{
                        a {
                            position: relative;
                            z-index: 3;
                            @extend %setFlex-center;
                            justify-content: center;
                            @include setSize(125px,125px);
                            margin-left: auto;
                            margin-right: auto;
                            border-radius: 50%;
                            background-color: #fff;
                            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) inset;
                            
                            &::before, &::after{
                                content: '';
                                position: absolute;
                                pointer-events: none;
                                z-index: -1;
                            }
                        }
        
                        img {
                            display: block;
                            max-width: 85px;
                            backface-visibility: hidden;
                        }
                    }
        
                    h3 {
                        display: block;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        margin-top: 1em;
        
                        color: $colorBase;
                        font-weight: 600;
                        --min-size: 16;
                        --max-size: 18;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
        
                        @include setMaxRwd{
                            padding-top: 5px;
                        }
                    }

                    .item{
                        color: #535353;
                        --min-size: 15;
                        --max-size: 16;
                        --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
                        font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
                        padding-left: get-vw(10px);
                        padding-right: get-vw(10px);
                        text-align: left;
                    }
                }

                &::before, &::after {
                    content: " ";
                    position: absolute;
                    left: 60px;
                    right: 0;
                    width: 100%;
                    height: 2px;
                    margin-bottom: 20px;
                }

                &::before {
                    top: 115px;
                    background-color: #e5e5e5;
                }

                &::after {
                    top: 116px;
                    background-color: #fff;
                }

                @include setWidthRwd(1100px,1500px){
                    padding-right: get-vw(5px);
                    &::before{
                        top: 7rem;
                    }

                    &::after {
                        top: 7.1rem;
                    }
                }

                @include setMaxRwd{
                    padding-top: get-vw-mobile(60px);
                    padding-bottom: get-vw-mobile(60px);
                    padding-left: get-vw-mobile(30px);

                    &::before,
                    &::after{
                        left: 0;
                        top: 8em;
                    }
                    &::after{
                        top: 8.1em;
                    }
                }

                .swiper-pagination{
                    bottom: 0px !important;
                    &-bullet {
                        background: #b39654;
                    }
                }
            }
        }
    }
}
  
@keyframes animated-stroke {
    0% {
        stroke-dasharray: 0 80%;
    }
    100% {
        stroke-dasharray: 10% 0%;
        stroke-dashoffset: 25%;
    }
}

// 
.titlebox{
    display: inline-block;
    padding-right: get-vw(70px);
    padding-top: get-vw(75px);
    border-right: $colorBase 1px solid;
}

.page{
    // 共用Banner形象圖
    &-banner{
        width: 100%;
        overflow: hidden;
        z-index: 1;

        @include setWidthRwd(900px,1100px){
            margin-top: 1%;
        }

        &::before,
        &::after{
            content: "";
            width: 100%;
            height: 0;
            padding-bottom: 5.3125%;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            left: 0;
            z-index: 1;
        }

        &::before{
            top: -1px;
            background-image: url(../images/index-cover-top.png);
            z-index: 3;
        }

        @include setMinRwd{
            margin-top: -4.5vw;
        }

        &.index{
            background: linear-gradient(to bottom,  #ffffff 0%,#f7f7f7 60%,#ffffff 60%,#f7f7f7 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

            @include setMaxRwd{
               height: get-vw-mobile(300px);
            }

            &::after {
                bottom: -1px;
                background-image: url(../images/index-cover.png);
            }
        }

        &-main{
            overflow: visible;
            &::after{
                display: none;
            }

            @include mobile{
                img{
                    width: 100%;
                    height: 125px;
                    object-fit: cover;
                }
            }
        }
    }
    
    // 共用Swiper
    &-gallerSwiper{
        overflow: hidden;
        // swiper 樣式
        .swiper{
            &-pagination{
                width: fit-content;
                display: inline-flex;
                align-items: center;
                gap: 8px;
                &-bullet {
                    @include setSize(50px,5px);
                    @include radius(0);
                    margin: 0 !important;
                    &-active {
                        background-color: $colorMain;
                    }
                }
            }
        }

        // 卡片內容
        .card{
            &-body{
                padding: 0;
            }

            &-title{
                font-weight: 400;
                line-height: 1.15;
                word-wrap: normal;
            }

            &-img-top{
                text-align: center;
                img{
                    display: inline-block;
                }
            }
        }

        .swiper{
            &-pagination{
                bottom: get-vw(10px) !important;

                @include setStyleMinRwd(760px){
                    &-bullet {
                        opacity: 0.2;
                        &-active{
                            opacity: 1;
                        }
                    }
                }

                @include setMaxRwd{
                    justify-content: center; 
                }
            }
        }

        // 首頁產品分類 swiper
        &-pro{
            width: 100%;
            padding-top: get-vw(15px);
            padding-bottom: get-vw(40px);
            .card{
                &-body{
                    @include setSize(100%,100%);

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @include setMinRwd{
                        position: absolute;
                        padding: 3em;
                        color: #fff;
                        background-color: rgba(49, 49, 49,0.85);
                    }
                }

                &-title{
                    @include fontSize(42px);
                    font-family: var(--fancy-slider-title-font-family);
                    border-bottom: $colorBase 1px solid;
                    text-align: center;
                    padding-bottom: 0.3em;
                    margin-bottom: 0.35em;

                    @include setMinRwd{
                        border-bottom-color: rgba(255,255,255,0.65); 
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                }

                @include setMinRwd{
                    &-linkNormal{
                        & ~ .card-body{
                            opacity: 0;
                            transition: var(--card-transition-duration);
                            .card-title,
                            .card-des{
                                transition-duration: var(--card-transition-duration);
                                transition-delay: var(--card-transition-delay);
                            }
    
                            .card-title{
                                transform: translateY(-1000%);
                            }
    
                            .card-des{
                                transform: translateY(1000%);
                            }
                        }
    
                        &:hover{
                            & ~ .card-body{
                                opacity: 1;
                                .card-title,
                                .card-des{
                                    transform: translateY(0);
                                }
                            }
                        }
                    }
                }

                @include pad{
                    flex-direction: row;
                    align-items: flex-start;
                    background-color: transparent;
                    
                    &-img-top{
                        @include radius(20px);
                        overflow: hidden;
                        flex-basis: 150px;
                        margin-top: 46px;
                    }

                    &-body{
                        padding-left: 1.25em;
                        padding-right: 0.25em;
                        flex: 0 0 calc(100% - 150px);
                    }

                    &-title{
                        @include fontSize(25px);
                    }
                }
            }

            .swiper{
                &-slide{
                    .card{
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    
                    @include setMinRwd{
                        &-active,
                        &-next{
                            .card{
                                border-bottom-right-radius: 0 !important
                            }
                        }

                        &-active{
                            .card{
                                border-top-left-radius: 40%;
                            }
                        }

                        &-next{
                            .card{
                                @include radius(20px);
                            }
                        }

                        .card{
                            border-bottom-right-radius: 40%;
                            overflow: hidden;
                        }
                    }
                }

                &-act{
                    &-container{
                        @extend %setFlex;
                        align-items: flex-end;
                        justify-content: end;
                        margin-bottom: 2.75em;
                        padding-right: 1em;

                        .swiper-pagination {
                            position: relative;
                            width: auto;
                            top: 0px;
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(100%,1px);
                                background-color: #cbcbcb;
                                position: absolute;
                                top: 25px;
                                z-index: -1;
                            }
                        }
                    }

                    &-button{
                        width: calc(3.5em + 10px);
                        padding-left: 10px;
                        @extend %setFlex-center;
                        justify-content: space-between;
                        .swiper-button-next,
                        .swiper-button-prev {
                            position: static;
                            color: $colorBase;
                            height: auto;
                            margin-top: 0;
                            &::after{
                                font-size: 14px;
                                @extend .fw-900
                            }
                        }
                    }
                }

                &-pagination{
                    &-fraction{
                        span{
                            color: #b9b9b9;
                            font-size: 15px;
                            font-family: $OpenSans-300;
                            padding: 0 2px;
                        }

                        .swiper-pagination-empty{
                            width: 30px;
                        }

                        .swiper-pagination-current{
                            position: relative;
                            color: $colorBase;
                            font-weight: 600;
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(30px,3px);
                                background-color: $colorMain;
                                position: absolute;
                                bottom: -2px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }

            @include setMaxRwd{
                display: flex;
                flex-direction: column;
                .swiper-act-container{
                    order: 1;
                    margin-top: 1.1em;
                }
            }
        }
    }

    // 內容
    &Grid{
        display: grid;
        grid-column-gap: 0;
        grid-auto-rows: 0fr 0fr 0fr;
        &-list{
            &-frAuto{
                grid-template-columns: repeat(1, auto) 1fr
            }
            
            &-1to1{
                grid-template-columns: 1fr 1fr;
            }

            &-2to1{
                grid-template-columns: 2fr 1fr 1fr;
            }
            
            &-avg{
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            &-1to2{
                grid-template-columns: 1fr 1fr 2fr;
            }
        }

        @include pad1080{
            // grid-template-columns: auto !important;
            display: block;
            width: 100%;
        }
    }

    &Flex{
        @extend %setFlex;
    }
}

/* swiper index banner */
.swiper-bannerIn{
	width: 100%;
	height: 100%;
    overflow: hidden;

        .swiper-container {
            width: 100%;
            height: 100%;
        }
        
        .swiper-image {
            height: 100%;
        }

        .swiper-image-inner {
            background-size: cover;
            background-position: center center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-blend-mode: overlay;
        }

        .swiper-slide{
            transition: all 1s linear;
            transition-delay: 0.5s;
            opacity: 0;
            overflow: hidden;
        }

        .swiper-slide.swiper-slide-next {
            opacity: 0;
        }

        .swiper-slide.swiper-slide-active{
            opacity: 1;
            width: 100% !important;
        }

        .swiper-slide.swiper-slide-active .swiper-image{
            overflow: hidden;
        }
        
        .swiper-image.swiper-image-active{
            img{
                width: 100%;
                height: 730px;
                object-fit: cover;

                @include setWidthRwd(1100px,1650px){
                    height: 35em;
                }
            }
        }

        .swiper-image-inner.swiper-image-active {
            transition: all 1s linear;
            transition-delay: 1s;
            background-blend-mode: multiply;
        }

        /* Arrows */
        .swiper-button-prev,
        .swiper-button-next {
            width: 39px;
            transition: var(--fancy-slider-transition-button);
            outline: none;
            position: absolute;
            top: 50%;
            // transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;

            &::after{
                display: none;
            }

            @include setMaxRwd{
                width: 40px;
                opacity: 0.75;
                svg {
                    image{
                        height: 45px;
                    }
                }
            }
        
            svg {
                display: block;
                transition: var(--fancy-slider-transition-button);
            }
        }

        .swiper-button-prev{
            left: 0;
            @include setMaxRwd{
                left: -2%;
            }
        }

        .swiper-button-next{
            right: 0;
            @include setMaxRwd{
                right: -2%;
            }
        }

        .swiper-pagination{
            width: 100%;
            position: absolute;
            text-align: center;
            transition: 300ms;
            transform: translate3d(0, 0, 0);
            z-index: 10;
            &-bullet {
                width: 14px;
                height: 14px;
                background: #fff;
                opacity: 0.4;
                &-active {
                    opacity: 1;
                    background: #fff;
                }
            }
        }
      
}
