@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("//fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("//fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap");
@import url("//fonts.googleapis.com/css2?family=Mitr&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
@import url("//fonts.googleapis.com/css2?family=Arimo&display=swap");
@import url("//fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("//fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@200..900&display=swap");
.is-wrapper *, a, body {
  color: #444444;
  font-size: 16px;
  font-family: "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  line-height: 1.65;
  font-weight: 400;
}

input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.contact-info, .pageFlex, .page-gallerSwiper-pro .swiper-act-container, .index-pageWrapper, .card-wrap, .box, .pagination-wrap, .mainContent, .cookies-wrapper, footer .footer-info-right-row, footer .footer-info-left {
  display: flex;
  flex-wrap: wrap;
}

.accordion-card-flex, .page-gallerSwiper-pro .swiper-act-button, .pageUndertake-info-listItem .Img a, .member-editor-buildCase-tab .btn-wrap .btn, .member-editor-buildCase-tab, .card-time, .btn-wrap, .radio_style, .title-mainWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.imgCenter-4-3 img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  margin: auto;
}

.font-size-1 {
  font-size: 87.5% !important;
}

.font-size-2 {
  font-size: 100% !important;
}

.font-size-3 {
  font-size: 112.5% !important;
}

.font-size-4 {
  font-size: 150% !important;
}

.font-size-5 {
  font-size: 175.5% !important;
}

.font-size-6 {
  font-size: 250% !important;
}

.font-size-7 {
  font-size: 275% !important;
}

.font-size-8 {
  font-size: 362.5% !important;
}

.font-size-9 {
  font-size: 450% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 1.75 !important;
}

.line-height-5 {
  line-height: 2 !important;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
  overflow-x: hidden;
}
body.fixed {
  overflow: hidden;
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1100px) {
  .hide {
    display: block !important;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.mw-auto {
  max-width: none !important;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1150 {
  width: 100%;
  max-width: 1150px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1050 {
  width: 100%;
  max-width: 1050px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.wrapper-750 {
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.wrapper-700 {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.wrapper-650 {
  width: 100%;
  max-width: 650px;
  margin: auto;
}

.is-wrapper * {
  letter-spacing: 0px !important;
}

/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}
.start-header .container {
  max-width: 1225px;
}
.start-header .container img {
  margin: 0 !important;
}

.navigation-wrap {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  transition: all 0.3s ease-out;
}
@media (min-width: 1101px) {
  .navigation-wrap {
    background: transparent;
  }
}
@media (max-width: 1100px) {
  .navigation-wrap {
    padding: 5px 0;
    z-index: 1052;
  }
  .navigation-wrap.bg-light {
    background-color: transparent !important;
  }
}

.navbar {
  font-size: 16px;
  font-size: 1rem;
}
@media (max-width: 1080px) {
  .navbar {
    justify-content: space-between;
    padding: 0;
  }
}
.navbar-brand {
  padding: 0;
  margin: 0;
}
.navbar-brand img {
  display: block;
  transition: all 0.3s ease-out;
}
@media (max-width: 1080px) {
  .navbar-brand {
    display: block !important;
    max-width: 10.9375vw;
  }
}
@media (max-width: 640px) {
  .navbar-brand {
    max-width: 26.5625vw;
  }
}
.navbar-light .navbar-toggler-icon {
  width: 25px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after, .navbar-light .navbar-toggler-icon::before {
  width: 25px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after {
  top: 8px;
}
.navbar-light .navbar-nav .nav-link {
  display: inline-flex;
}
.navbar-collapse {
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 1101px) {
  .navbar-collapse {
    flex: auto;
    flex-wrap: nowrap;
  }
}
@media (min-width: 800px) and (max-width: 1500px) {
  .navbar-collapse {
    width: 100%;
    margin-top: 0;
    flex-wrap: nowrap;
  }
}
@media (min-width: 1070px) and (max-width: 1300px) {
  .navbar-collapse {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1080px) {
  .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    background: #fff;
    transition: 0.15s;
    overflow-y: auto;
    max-width: 60vw;
  }
  .navbar-collapse, .navbar-collapse > ul {
    z-index: 100;
  }
  .navbar-collapse > ul {
    position: relative;
    justify-content: flex-start !important;
  }
  .navbar-collapse.show {
    display: block !important;
    left: 0;
  }
}
.navbar-overlay {
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}
.navbar-overlay.active {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1101px) {
  .navbar-overlay {
    display: block;
  }
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active, .navbar-toggler:focus {
  outline: none;
}
@media (max-width: 1080px) {
  .navbar-toggler {
    display: block !important;
  }
}
.navbar-toggler .hamburger-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
}
.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
}
.navbar-toggler .hamburger-toggle .hamburger span {
  width: 100%;
  height: 4px;
  position: absolute;
  background: #000;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
  left: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 10%;
  transform-origin: 50% 50%;
  transform: translate(0% -50%) !important;
}
.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 0;
  top: auto;
  bottom: 0;
  transform-origin: 50% 50%;
}
.navbar-toggler .hamburger-toggle .hamburger.active span {
  position: absolute;
  margin: 0;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:first-child {
  top: 45%;
  transform: rotate(45deg);
}
.navbar-toggler .hamburger-toggle .hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:last-child {
  top: 45%;
  transform: rotate(-45deg);
}
.navbar-toggler.fixed {
  display: none !important;
  position: fixed;
  left: 59%;
  top: 2%;
  z-index: 1001;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span {
  background-color: #fff;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:first-child {
  transform: rotate(40deg);
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:last-child {
  transform: translateY(-18px) rotate(-40deg);
}
.navbar-toggler.fixed.active {
  display: block !important;
}
.navbar-nav.link {
  justify-content: space-between;
}
@media (min-width: 1101px) {
  .navbar-nav.link .nav-item {
    flex-grow: 1;
    text-align: center;
  }
  .navbar-nav.link .nav-item a {
    display: block;
    width: 100%;
  }
  .navbar-nav.link .nav-item .nav-link {
    line-height: 2;
    padding-top: 1em !important;
    transition: 0.25s;
  }
  .navbar-nav.link .nav-item-logo {
    flex: 1 0 190px;
  }
}
.navbar-nav.link .nav-item:hover .nav-link, .navbar-nav.link .nav-item.active .nav-link {
  color: #565599;
}
.navbar-nav.link .dropdown-menu {
  box-shadow: none;
  background: transparent;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-item {
  font-size: 16px;
  font-size: 1rem;
  background-color: transparent !important;
  color: #000 !important;
  line-height: 1.05;
  letter-spacing: 0px;
}
@media (min-width: 1101px) {
  .navbar-nav.link .dropdown-menu-list li .dropdown-item {
    white-space: normal;
    padding: 10px 6px;
    text-align: center;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-item:hover {
    background-color: #565599 !important;
    color: white !important;
  }
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  text-align: center;
  color: #666;
  padding-left: 1.25rem;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item::before {
  content: "";
  display: block;
  width: 10px;
  height: 1px;
  background-color: #696969;
  position: absolute;
  top: 12.5px;
  left: 0;
}
@media (max-width: 1100px) {
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
    padding-top: 0;
    margin-top: -2px;
    padding-left: 20px;
    max-height: 55vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    position: static;
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
    font-size: 15px;
    font-size: 0.9375rem;
    background-color: transparent !important;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item:last-child {
    border-bottom: none;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier.active {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 1101px) {
  .navbar-nav.link {
    position: relative;
    width: 100%;
    align-items: center;
    margin: auto;
  }
  .navbar-nav.link .nav-link {
    text-transform: uppercase;
    flex-direction: column;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    left: 50%;
    padding: 0 !important;
    transform: translate3d(-50%, 0px, 0);
    min-width: 12em;
    max-height: 100vw;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu .dropdown-menu-list {
    width: 100%;
    padding: 10px;
    background: #e8e4ed;
    max-height: 100vw;
    border-radius: 5px;
    position: relative;
    top: 5px;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu .dropdown-menu-list::before {
    content: "";
    display: block;
    width: 70%;
    height: 2px;
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-nav.link .dropdown-item:hover {
    background-color: transparent;
    color: #000;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.link {
    flex-direction: column;
  }
  .navbar-nav.link .nav-item {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 1101px) {
  .navbar-nav.info {
    align-items: center;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.info {
    flex-wrap: wrap;
  }
  .navbar-nav.info > li {
    flex: 1;
  }
  .navbar-nav.info > li.nav-item-search {
    display: none;
  }
}
.navbar-mobile-icon {
  width: 80px;
  height: 40px;
  color: #000 !important;
  text-align: right;
  padding-right: 18px;
  position: absolute;
  right: 0;
  top: 5px;
  line-height: 40px;
}
.navbar-mobile-icon::after {
  display: none;
}
.navbar-mobile-icon.show .bi {
  transform: rotate(180deg);
}

.scroll-on {
  position: fixed;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 4px 0 6px;
  transition: all 0.3s ease-out;
  background-color: #fff;
}
.scroll-on .navbar {
  padding: 0;
}
.scroll-on .nav-link {
  font-size: 1rem !important;
}
.scroll-on .nav-item-logo {
  flex: 1 0 1.5em !important;
}
@media (min-width: 1101px) {
  .scroll-on .navbar-brand img {
    transition: all 0.3s ease-out;
  }
  .scroll-on .search-formList {
    top: 105px;
  }
}

.nav-link {
  position: relative;
  display: inline-block;
  color: #444444 !important;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 600;
  padding: 5px 0 !important;
  transition: all 200ms linear;
}
@media (min-width: 1101px) {
  .nav-link {
    line-height: 3;
  }
}
@media (max-width: 1080px) {
  .nav-link {
    padding: 10px 0 !important;
  }
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}
.nav-item.active .nav-link, .nav-item:hover .nav-link {
  color: #565599 !important;
}
.nav-item .icon {
  display: inline-block;
  vertical-align: top;
  flex: none;
  margin-top: -2px;
}
.nav-item .icon .bi-caret-down-fill {
  font-size: 12px;
  color: #000;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-left: 1.5em;
}
.nav-item img {
  margin: 0 !important;
}
@media (max-width: 1100px) {
  .nav-item .dropdown-menu-list li {
    position: relative;
    margin-bottom: 0.25em;
  }
  .nav-item .dropdown-menu-list li .dropdown-item {
    padding-left: 0;
  }
  .nav-item .dropdown-menu-list li .navbar-mobile-icon {
    right: -1.3em;
    top: -4px;
  }
}
@media (max-width: 1080px) {
  .nav-item::after {
    display: none;
  }
  .nav-item::before {
    position: absolute;
    display: block;
    top: 24px;
    left: 5px;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
  }
}

/* #Primary style
================================================== */
.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-item {
  font-size: 14px;
  line-height: 1.5;
  transition: all 200ms linear;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  background-color: #565599;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  min-width: 6.75em;
  max-height: 0;
  display: none;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item .dropdown-menu.show {
  display: block;
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0, 0);
}
@media (min-width: 1101px) {
  .nav-item .dropdown-menu {
    border-radius: 5px;
  }
}
@media (max-width: 1100px) {
  .nav-item .dropdown-menu {
    padding-top: 0 !important;
  }
  .nav-item .dropdown-menu .dropdown-menu-list {
    padding-left: 10px;
  }
  .nav-item .dropdown-menu .dropdown-menu-list li {
    margin-bottom: 0.5em;
  }
}
.nav-item-lang {
  position: relative !important;
}
@media (min-width: 1101px) {
  .nav-item-lang .dropdown-toggle {
    border-bottom: #000 1px solid;
    line-height: 1.75;
    padding: 5px 10px 2px !important;
  }
}
@media (max-width: 1080px) {
  .nav-item-lang .dropdown-toggle {
    display: none !important;
  }
}

@media (max-width: 1100px) {
  .nabar-icon-mobile {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5em;
  }
  .nabar-icon-mobile .icon {
    margin-left: 5px;
  }
}

.toggle-search {
  position: relative;
  top: 2px;
}
.toggle-search .icon-txt {
  --min-size: 18;
  --max-size: 34;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.toggle-search .icon-search {
  margin-top: -8px;
}

.search-formList {
  position: absolute;
  top: 160px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
  transition: 0.5s;
  display: none;
}
.search-formList.open {
  display: block;
}
@media (min-width: 1101px) and (max-width: 1200px) {
  .search-formList {
    top: 5.25em;
  }
}
@media (max-width: 1100px) {
  .search-formList {
    top: 5.45em;
  }
}
.search-formInput {
  position: absolute;
  top: 0px;
  right: 0%;
  width: 100%;
  max-height: 60px;
  overflow: hidden;
  background-color: #e1e4e7;
  max-width: 40em;
}
.search-formInput fieldset {
  position: relative;
  margin: 0 60px 0 0;
  padding: 0;
  border: none;
}
.search-formInput input {
  display: block;
  height: 60px;
}
.search-formInput input[type=search] {
  width: 100%;
  padding: 0 5px 0 22px;
  background: transparent;
  border: none;
}
.search-formInput input[type=submit] {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  color: white;
  background: #565599;
  border: none;
  cursor: pointer;
}

footer {
  padding: 1em;
  background-color: #565599;
}
footer, footer a {
  color: #fff;
}
footer .tag-item, footer .tag-item-current, footer .tag-item-date {
  font-size: 15px;
  background-color: #161616;
  color: #bfbfbf;
  padding: 5px 5px 6px;
}
@media (min-width: 1101px) {
  footer .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
footer .footer-info p {
  margin-bottom: 10px;
}
footer .footer-info .list-group-item {
  background-color: transparent;
  color: #adabab;
  padding-left: 0;
  padding-bottom: 0;
  border: none;
}
footer .footer-info .copyright {
  font-size: 15.5px;
  font-size: 0.96875rem;
}
@media (max-width: 1100px) {
  footer .footer-info .copyright {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
footer .footer-info-logo {
  padding-bottom: 0.65em;
}
footer .footer-info-logo ~ p {
  font-size: 17px;
}
@media (max-width: 1100px) {
  footer .footer-info-logo ~ p {
    font-size: 1rem;
    text-align: center;
  }
}
footer .footer-info-left {
  flex: 1;
}
@media (max-width: 1100px) {
  footer .footer-info-left {
    flex-direction: column;
  }
}
footer .footer-info-right {
  text-align: right;
  padding-top: 1.75em;
}
footer .footer-info-right-row .item {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 0.5rem;
  border-right: #fff 1px solid;
}
footer .footer-info-right-row .item:last-child {
  border-right: none;
  padding-right: 0;
}
footer .footer-info-right-row .item a {
  display: block;
  line-height: 1;
}
footer .footer-info-right .iware {
  background: transparent;
}
@media (max-width: 1100px) {
  footer .footer-info-right {
    text-align: center;
    padding-top: 0.5em;
  }
  footer .footer-info-right ul.footer-info-right-row {
    margin-bottom: 0.5rem;
    justify-content: center;
  }
  footer .footer-info-right ul.footer-info-right-row .item {
    margin-bottom: 0.75rem;
  }
  footer .footer-info-right p {
    font-size: 13px !important;
  }
}
@media (min-width: 1101px) {
  footer {
    padding: 20px 30px 25px;
  }
}
@media (max-width: 1100px) {
  footer {
    padding-bottom: 6em;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}
@media (min-width: 800px) and (max-width: 1000px) {
  .divider {
    display: none;
  }
}

.iware {
  display: inline-block;
  background: #161616;
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 4px 8px;
}
.iware, .iware a {
  color: #bfbfbf;
}
.iware a:hover {
  text-decoration: underline;
}
.iware b {
  font-weight: 100;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
  background: #504f97;
  padding: 8px 12px;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
@media (min-width: 1101px) {
  .float-link .link-btn.nabar-icon-mobile {
    display: none;
  }
}
.float-link .link-btn.nabar-icon-mobile img {
  max-width: 100%;
}
.float-link .link-btn.social {
  display: flex;
  color: #fff;
}
@media (min-width: 1101px) {
  .float-link .link-btn.social {
    flex-direction: column;
    border-bottom: #fff 1px solid;
    padding-bottom: 10px;
    margin-bottom: 5px;
  }
  .float-link .link-btn.social a {
    font-size: 1.2rem;
    line-height: 2;
  }
}
@media (max-width: 1100px) {
  .float-link .link-btn.social {
    flex: 1;
    justify-content: space-around;
    align-items: center;
    padding: 0;
  }
}
.float-link .link-btn .social-icon {
  margin-top: 5px;
  margin-bottom: 5px;
}
.float-link .link-btn .social-icon-mobile {
  flex: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7.5px;
  padding-bottom: 2.5px;
  font-size: 0.975em;
}
@media (max-width: 1100px) {
  .float-link .link-btn .social-icon-mobile-line {
    background-color: #4CAF50;
  }
}
@media (max-width: 1100px) {
  .float-link .link-btn .social-icon-mobile svg image {
    width: 33px;
  }
}
@media (min-width: 1101px) {
  .float-link .link-btn .social-txt {
    writing-mode: vertical-lr;
    margin-top: 5px;
    letter-spacing: 3px;
  }
}
.float-link .link-btn.top {
  display: flex;
  justify-content: center;
  color: #fff;
}
.float-link .link-btn.top a {
  line-height: 1.3;
}
@media (max-width: 1100px) {
  .float-link .link-btn.top {
    width: 80px;
  }
  .float-link .link-btn.top a {
    line-height: 1.15;
  }
}
.float-link.active {
  right: 0px;
}
@media (max-width: 1600px) {
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1100px) {
  .float-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    padding: 0;
    margin: 0;
  }
  .float-link .link-btn {
    text-align: center;
    padding: 6px;
  }
  .float-link .link-btn .link-btn-toggle {
    color: #fff;
  }
  .float-link .link-btn .icon {
    margin-left: 0;
  }
  .float-link .link-btn .icon small {
    font-size: 100%;
    padding-right: 5px;
  }
}

.cookies {
  display: none !important;
  width: 100%;
  background: #303030;
  color: #fff;
  font-size: 16px;
  font-size: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 60;
}
.cookies p {
  font-weight: 400;
  text-align: center;
  line-height: 2;
}
@media (min-width: 1101px) {
  .cookies p {
    text-align: left;
    margin-bottom: 0;
  }
}
.cookies .btn {
  text-decoration: none !important;
  border-radius: 10px !important;
}
@media (min-width: 800px) {
  .cookies .btn {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
}
@media (max-width: 640px) {
  .cookies .btn {
    display: block;
    width: 100%;
    font-size: 16px;
    font-size: 1rem;
  }
}
.cookies-wrapper {
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1101px) {
  .cookies-wrapper {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
  }
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
.cookies.close-cookies {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}
@media (max-width: 810px) {
  .cookies.close-cookies {
    bottom: -2%;
  }
}
@media (min-width: 1101px) {
  .cookies .btn {
    font-size: 18px;
    font-size: 1.125rem;
    margin-left: 2.5em;
    padding: 0.5rem 2.5rem;
  }
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    border-top: none;
    margin: 0.5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 14px;
    font-size: 0.875rem;
  }
  .cookies.display {
    flex-direction: column;
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
.article,
article {
  width: 100%;
  font-size: 100%;
}

.in-content {
  background-color: #fafafa;
  padding-top: 1.5789473684vw;
  padding-bottom: 1.5789473684vw;
}
.in-content .article {
  --min-size: 17;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
@media (min-width: 1100px) and (max-width: 1700px) {
  .in-content {
    padding-left: 5em;
    padding-right: 5.5em;
  }
}
@media (max-width: 1100px) {
  .in-content {
    padding: 3.90625vw;
  }
}

.imgCenter-4-3 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 59.5%;
  overflow: hidden;
}
.imgCenter-4-3 img {
  max-height: none;
}

.g-recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1100px) {
  .g-recaptcha {
    margin-bottom: 1rem;
  }
}

.fw-400, .accordion-card-flex {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600, .searchResult-wrapper .searchResult-info-headTitle {
  font-weight: 600 !important;
}
.fw-900, .page-gallerSwiper-pro .swiper-act-button .swiper-button-next::after,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev::after, .card-btn-more, .card-time-date, .heading-title-article-slogan, .heading-title-txt, .heading-title-txt-en, .title-ch, .title-en, .link-head {
  font-weight: 900 !important;
}

.boxShadow, .member-content .form-box {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.bt-solid-c1 {
  border-top: 1px #ccc solid;
}

.mainContent {
  overflow: hidden;
}
.mainContent-wrap {
  padding-bottom: 2em;
  background-color: #f8f8f8;
}
@media (max-width: 1100px) {
  .mainContent-wrap {
    padding-top: 1em;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1101px) {
  .mainContent-info {
    width: 300px;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 300px);
    padding-left: 80px;
  }
}
@media (max-width: 1100px) {
  .mainContent-info {
    display: none;
  }
}
.mainContent-list {
  max-width: 100%;
  flex: 1;
}
@media (max-width: 1100px) {
  .mainContent-list {
    padding: 0.5em 0.1em;
  }
}
@media (max-width: 1024px) {
  .mainContent {
    flex-direction: column;
  }
}

.link-wrap {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0px 10px 20px 0;
}
.link-wrap ul > li > a {
  position: relative;
  display: block;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #444444;
  padding: 0.75em 50px 0.75rem 30px;
  border-bottom: dotted 1px #777;
}
.link-wrap ul > li > a::after {
  content: "\f4fe";
  font-family: "bootstrap-icons";
  display: block;
  color: #000;
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -15px;
  font-weight: 100;
  font-size: 22px;
  font-size: 1.375rem;
}
.link-wrap ul > li > a:hover, .link-wrap ul > li > a.active {
  background-color: #565599;
}
.link-wrap ul > li > a:hover, .link-wrap ul > li > a:hover::after, .link-wrap ul > li > a.active, .link-wrap ul > li > a.active::after {
  color: #fff;
}
.link-wrap ul > li > a:hover::after, .link-wrap ul > li > a.active::after {
  content: "\f2ea";
}
.link-wrap ul > li > a.active {
  border-top-right-radius: 30px;
}
.link-wrap ul > li > ol a {
  position: relative;
  display: block;
}
.link-wrap ul > li > ol li {
  border-bottom: #fff 1px dotted;
  padding-left: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.link-wrap ul > li > ol li:first-child {
  padding-top: 15px;
}
.link-wrap ul > li > ol li a:hover, .link-wrap ul > li > ol li a.active {
  color: #565599;
}
.link-wrap .first_menu.open {
  display: block;
}
@media (min-width: 1101px) {
  .link-wrap .first_menu {
    display: none;
  }
}
@media (min-width: 1020px) and (max-width: 1500px) {
  .link-wrap a {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (max-width: 1024px) {
  .link-wrap {
    margin-bottom: 1rem;
  }
}
.link-head {
  font-size: 30px;
  font-size: 1.875rem;
  color: #565599;
  padding-left: 0.75rem;
  margin-bottom: 1.5rem;
}

.link-horizontal-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}
.link-horizontal-wrap .list-group {
  border: #e5e5e5 2px solid;
  flex-wrap: wrap;
  overflow: hidden;
}
.link-horizontal-wrap .list-group, .link-horizontal-wrap .list-group-item {
  border-radius: 2em;
}
.link-horizontal-wrap .list-group-item {
  position: relative;
  border: none;
}
.link-horizontal-wrap .list-group-item a {
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #8c8c8d;
  display: block;
  text-align: center;
  padding: 0.55em 1em;
}
@media (min-width: 1101px) {
  .link-horizontal-wrap .list-group-item ~ li::before {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    background-color: #e5e5e5;
    position: absolute;
    left: -0.5em;
    top: 50%;
    margin-top: -2.5px;
    border-radius: 50%;
  }
}
.link-horizontal-wrap .list-group-item:only-child {
  margin-right: 0;
}
.link-horizontal-wrap .list-group-item.cur a {
  color: #fff;
  background-color: #565599;
  border-radius: 1.4em 1.5em 0em 1.4em !important;
}
.link-horizontal-wrap .list-group-item.cur:last-child a {
  border-radius: 1.4em 1.5em 1.4em 0em !important;
}
.link-horizontal-wrap .list-group-item.cur:only-child a {
  border-radius: 1.4em !important;
}
@media (min-width: 1101px) {
  .link-horizontal-wrap .list-group-item {
    flex: none;
    min-width: 10em;
    max-width: 100%;
    margin-right: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .link-horizontal-wrap .list-group-item:hover a {
    color: #fff;
    background-color: #565599;
    border-radius: 1.4em 1.5em 0em 1.4em !important;
  }
  .link-horizontal-wrap .list-group-item:hover:last-child a {
    border-radius: 1.4em 1.5em 1.4em 0em !important;
  }
  .link-horizontal-wrap .list-group-item:hover:only-child a {
    border-radius: 1.4em !important;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .link-horizontal-wrap .list-group-item {
    margin-right: 0.5em;
  }
}
@media (max-width: 1100px) {
  .link-horizontal-wrap .list-group-item.cur a {
    border-radius: 1.4em !important;
  }
}
@media (max-width: 1100px) {
  .link-horizontal-wrap .list-group {
    width: 100%;
    flex-wrap: wrap;
  }
  .link-horizontal-wrap .list-group-item {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0 !important;
  }
}
@media (max-width: 1100px) {
  .link-horizontal-wrap {
    display: none;
  }
  .link-horizontal-wrap.open {
    display: flex;
  }
}

.btn.list-group-mobile-btn {
  color: #fff !important;
  display: inline-flex;
  justify-content: space-between;
  border-radius: 3em !important;
}

.unit {
  position: relative;
  display: block;
  line-height: 1.5;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.unit .btn {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.unit-icon {
  padding-left: 1.5em !important;
  padding-right: 0;
}
.unit-icon .icon {
  margin-top: 0;
  position: absolute;
  left: 0;
  top: -0.1rem;
}
@media (max-width: 1100px) {
  .unit {
    text-align: left;
    font-size: 16px;
  }
  .unit-icon .icon {
    top: 0.85rem;
  }
}

.select_style::after {
  content: "\f282";
  font-family: "bootstrap-icons" !important;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  right: 8px;
  top: 1px;
}
.select_style select.form-control {
  background-color: #fff;
  border: 1px solid #e6eaea !important;
  text-align: left;
  border-radius: 0;
  height: 40px !important;
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.select_style select {
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
.select_style select,
.select_style input {
  border: none !important;
  height: 100% !important;
}
.select_style .form-control {
  border-radius: 0;
}
.select_style .disabled {
  background-color: #e9ecef;
}
.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.title-wrap {
  width: 100%;
  text-align: center;
  padding: 10px 0 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.title-wrap .btn .icon {
  font-size: 23px;
  font-size: 1.4375rem;
}
@media (max-width: 1100px) {
  .title-wrap {
    margin-top: 0;
    margin-bottom: 2px;
  }
}
.title-spacing-2 {
  letter-spacing: 2px;
}
.title-spacing-3 {
  letter-spacing: 3px;
}
.title-spacing-4 {
  letter-spacing: 4px;
}
.title-spacing-5 {
  letter-spacing: 5px;
}
.title-en {
  font-family: "Mitr", sans-serif;
  font-size: 35px;
  font-size: 2.1875rem;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (max-width: 810px) {
  .title-en {
    font-size: 6.25vw;
  }
}
.title-ch {
  position: relative;
  font-size: 45px;
  font-size: 2.8125rem;
  color: #595757;
}
@media (max-width: 810px) {
  .title-ch {
    font-size: 4.375vw;
  }
}
@media (max-width: 640px) {
  .title-ch {
    font-size: 7.5vw;
  }
}
.title-mainWrap {
  justify-content: space-between;
  padding-top: 0.25em;
}
@media (max-width: 1100px) {
  .title-mainWrap {
    padding-top: 0.65em;
    padding-bottom: 0.65em;
  }
}
.titleStyle {
  position: relative;
  padding: 0.25em;
}
.titleStyle::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #e50012;
  border-radius: 50%;
  position: absolute;
  right: -8px;
  top: 8px;
}
.title__topLine {
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: #000;
}
.title__solid {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #b3b3b3;
}
.title__underLine {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #000;
}
.title-left-circle {
  position: relative;
  padding-left: 30px;
  z-index: 1;
}
.title-left-circle::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: #565599 2px solid;
  border-radius: 50%;
  top: 11px;
  left: 10px;
  z-index: -1;
}
.title-right-squre {
  position: relative;
  padding-left: 15px;
  z-index: 1;
}
.title-right-squre::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #0125fd;
  position: absolute;
  bottom: 2px;
  right: -40px;
  z-index: -1;
}
.title__topHead::before {
  content: "";
  display: block;
  width: 30px;
  height: 0.5rem;
  background-color: #565599;
  margin-bottom: 1rem;
}

.heading-title {
  text-align: left;
  margin-bottom: 1em;
}
.heading-title-txt {
  --min-size: 18;
  --max-size: 20;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.heading-title-txt, .heading-title-txt-en {
  position: relative;
  padding-left: 35px;
  padding-right: 0.5rem;
}
.heading-title-txt-en {
  --min-size: 20;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 0.25em;
  font-family: Helvetica;
}
.heading-title::before {
  content: "";
  display: block;
  width: 20px;
  height: 3px;
  background-color: #565599;
  position: relative;
  top: 15px;
}
.heading-title-article {
  border: #565599 1px solid;
  border-top: none;
  padding: 0 2em 1em;
}
.heading-title-article::before, .heading-title-article::after {
  content: "";
  display: block;
  width: 1.3em;
  height: 1px;
  background-color: #565599;
  position: absolute;
  top: 0;
}
.heading-title-article::before {
  left: 0;
}
.heading-title-article::after {
  right: 0;
}
.heading-title-article-slogan {
  --min-size: 24;
  --max-size: 48;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #565599;
  position: relative;
  top: -0.65em;
  margin-bottom: -0.65em;
  line-height: 1.1;
  text-transform: uppercase;
}
.heading-title-article-txt {
  --min-size: 18;
  --max-size: 24;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  margin-bottom: 0.3em;
  color: #644689;
}

.mainheading-title {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}
.mainheading-title-name {
  --min-size: 20;
  --max-size: 22;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding-left: 0.5em;
  font-family: "Noto Serif TC", serif;
}
.mainheading-title-subname {
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #979595;
}

@media (max-width: 1100px) {
  .breadcrum-wrap {
    margin-top: 2.34375vw;
    margin-bottom: 2.34375vw;
  }
}
.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #b4b3b3;
  background-color: transparent;
  margin-bottom: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item {
  color: #b4b3b3;
  text-transform: uppercase;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  padding-left: 0.15em;
  padding-right: 0.5em;
  color: #b4b3b3;
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 2em;
    padding-right: 2em;
  }
}
@media (max-width: 1100px) {
  .breadcrum-wrap .breadcrumb {
    padding-top: 0.15em;
    padding-bottom: 0.15em;
    padding-left: 1em;
  }
}
.breadcrum-wrap .icon {
  margin-top: -8px;
  margin-right: 2px;
}

.pagination-wrap {
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.pagination-wrap .pagination {
  justify-content: center;
}
.pagination-wrap .pagination .page-link {
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #6e6d6d;
  font-family: "Roboto";
  background-color: transparent;
  border-radius: 0%;
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #fff;
  background-color: #565599;
  border-color: #565599;
}
.pagination-wrap .pagination-select {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-wrap .pagination-select select {
  text-align: center;
  appearance: auto;
  font-size: 15px;
  font-size: 0.9375rem;
}
.pagination-wrap .pagination-select .pagination-item {
  padding-left: 20px;
}
.pagination-wrap .pagination-mobile {
  max-width: 120px;
}
@media (min-width: 1101px) {
  .pagination-wrap .pagination-mobile .pagination-select {
    width: auto;
  }
  .pagination-wrap .pagination-mobile .pagination-select select {
    max-width: 45px;
  }
}
@media (max-width: 1100px) {
  .pagination-wrap {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.text-main {
  color: #565599 !important;
}
.text-itemSub {
  color: #7d7d7d !important;
}
.text-underLine {
  text-decoration: underline !important;
}
.text-uppercase, .mainheading-title-name {
  text-transform: uppercase;
}
.text-italic {
  font-style: italic;
}

.bg-itemSub {
  background-color: #6f6e6e !important;
}

.justify {
  text-align: justify;
}

.flex-none {
  flex: none !important;
}
@media (max-width: 640px) {
  .flex-none {
    width: 100%;
  }
}
.flex-auto {
  flex: auto !important;
}
.flex-1 {
  flex: 1 !important;
}

.tag-item, .tag-item-current, .tag-item-date {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  font-size: 14px;
  line-height: 1;
  padding: 6px;
  text-align: center;
  background-color: #eeeeee;
  color: #5b5a5a;
}
.tag-item-current, .tag-item-date {
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff;
  padding: 8px 16px;
  border-radius: 6px;
}
.tag-item-current {
  background-color: #00b7ee;
}
.tag-item-date {
  background-color: #bfbf85;
}
.tag-item-wrap .tag-item ~ .tag-item, .tag-item-wrap .tag-item-current ~ .tag-item, .tag-item-wrap .tag-item-date ~ .tag-item, .tag-item-wrap .tag-item ~ .tag-item-current, .tag-item-wrap .tag-item-current ~ .tag-item-current, .tag-item-wrap .tag-item-date ~ .tag-item-current, .tag-item-wrap .tag-item ~ .tag-item-date, .tag-item-wrap .tag-item-current ~ .tag-item-date, .tag-item-wrap .tag-item-date ~ .tag-item-date {
  margin-left: 5px;
}
@media (min-width: 1101px) {
  .tag-item-wrap {
    padding-left: 15px;
  }
}
@media (max-width: 1100px) {
  .tag-item-wrap {
    padding-top: 10px;
  }
}

.detail-label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail-label-other {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}
.detail-blockquote {
  display: block;
  background: #fff;
  padding: 15px 20px 12px 25px;
  margin: 0 0 20px;
  position: relative;
  /*Font*/
  font-size: 16px;
  /*Borders - (Optional)*/
  border-left: 10px solid #565599;
  /*Box Shadow - (Optional)*/
  box-shadow: 2px 2px 15px #ccc;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  padding-left: 30px;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  position: absolute;
  top: -1px;
  left: 0;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 4px;
}
.radio_style input:checked + label::after {
  background-color: #565599;
}

.policy-section p,
.policy-section li {
  color: #333;
  font-size: 1rem;
  line-height: 1.5;
}
.policy-section .title {
  font-weight: bold;
  font-size: x-large;
  margin-bottom: 0.5em;
}
.policy-section .policy-sec {
  margin-bottom: 40px;
}

.form-control,
.btn {
  border-radius: 0.5em;
}

.form-control {
  height: auto;
}
.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal-content {
  border-color: #accccd;
  border-width: 3px;
}
.modal-open {
  padding-right: 0 !important;
}
@media (min-width: 1101px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}

.btn:hover {
  background-color: #565599 !important;
  border-color: #565599 !important;
  color: #fff !important;
}

.btn:hover {
  background-color: #565599 !important;
  border-color: #565599 !important;
  color: #fff !important;
}

.btn {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 100;
  border-radius: 0 !important;
  padding: 0.35em 1.5em 0.5em;
}
@media (max-width: 1100px) {
  .btn {
    font-size: 16px;
    font-size: 1rem;
    padding-left: 1em;
    padding-right: 1em;
  }
}
.btn .icon {
  vertical-align: top;
  margin-top: 0px;
  margin-right: 3px;
}
.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}
.btn-skin {
  background-color: #dec9bc;
}
.btn-main {
  background-color: #565599;
  color: #fff;
}
.btn-white {
  background-color: #fff;
}
.btn-outline-dark {
  border: 1px solid #212529 !important;
}

.js-toggle-btn .btn {
  font-size: 16px;
  font-size: 1rem;
  border-radius: 20px;
  border: #eee 1px solid;
}
.js-toggle-btn .btn i {
  color: currentColor;
}
.js-toggle-btn .btn .icon {
  margin-top: 0;
}

.js-open {
  display: block !important;
  margin-top: 5px;
}

.box {
  align-items: stretch;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px;
}
.box p {
  margin-bottom: 0;
}
.box-head {
  flex: none;
  color: #717171;
  padding-left: 10px;
}
.box-text {
  flex: 1;
}

.card {
  flex-grow: 1;
  margin-left: 2.5em;
  margin-right: 2.5em;
  margin-bottom: 2em;
  border: none;
  text-align: left;
  border-radius: 0;
}
@media (min-width: 1101px) {
  .card__2 {
    flex: 0 0 calc(50% - 5em);
    max-width: calc(50% - 5em);
  }
}
@media (min-width: 1101px) {
  .card__3 {
    flex: 0 0 calc(33.3333333333% - 5em);
    max-width: calc(33.3333333333% - 5em);
  }
}
@media (min-width: 1101px) {
  .card__4 {
    flex: 0 0 calc(25% - 5em);
    max-width: calc(25% - 5em);
  }
}
@media (max-width: 1100px) {
  .card {
    margin-left: 0.5em;
    margin-right: 0.5em;
    flex: 0 0 calc(50% - 1em);
    max-width: calc(50% - 1em);
  }
}
.card__full {
  flex: 0 0 calc(100% - 5em) !important;
  max-width: calc(100% - 5em) !important;
}
@media (min-width: 1101px) {
  .card__horizontal {
    flex-direction: row;
  }
}
.card-img-top {
  border-radius: 0px;
}
.card-img-top img {
  display: block;
  width: 100%;
}
.card-img-top + .card-caption {
  margin-top: 10px;
}
.card-time-date {
  font-size: 24px;
  font-size: 1.5rem;
}
@media (max-width: 1100px) {
  .card-time-date {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.card-time-year {
  font-size: 16px;
  font-size: 1rem;
  color: #8b8b8b;
}
@media (min-width: 1101px) {
  .card-time {
    margin-bottom: 1em;
  }
}
.card-title {
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 900;
  line-height: 1.5;
}
.card-caption {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}
.card-body {
  padding: 1.25em;
}
.card-text {
  font-size: 16px;
  font-size: 1rem;
  color: #5b5a5a;
  font-weight: 100;
  line-height: 1.3;
}
.card-btn {
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 900;
  background-color: #c9c9c9 !important;
  color: #fff !important;
  margin-bottom: 1em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.card-btn:hover {
  background-color: #565599 !important;
}
.card-btn-more {
  font-size: 14px;
  font-size: 0.875rem;
  border-bottom: #333 1px solid;
  padding-bottom: 2px;
}
@media (max-width: 640px) {
  .card-btn {
    padding-left: 0;
    padding-right: 0;
  }
}
.card-linkNormal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.card-wrap {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
.card:hover .card-btn {
  color: #252525;
}
.card:hover .card-btn::after {
  background-color: #252525;
}
@media (max-width: 1100px) {
  .card {
    margin-bottom: 1em;
  }
  .card-title {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-top: 0.25em;
  }
  .card-body {
    padding: 0.75em;
  }
  .card-caption {
    padding-top: 0.125em;
    padding-bottom: 0.25em;
  }
}

@media (min-width: 1101px) {
  .form-inMain-wrap {
    align-items: flex-end;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.form-wrap {
  background-color: #fff;
  border: #eee 1px solid;
  padding: 1.5em;
}
.form-wrap legend {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 2.5em;
}
.form-wrap .form-control {
  border-radius: 0;
  border-width: 0 0 1px 0px;
}
@media (min-width: 1101px) {
  .form-wrap {
    padding: 50px;
  }
}
.form-flex {
  display: flex;
  margin-bottom: 25px;
}
.form-flex label {
  flex: none;
  width: 80px;
}
.form-check {
  padding-left: 0;
}
.form-box .title {
  text-align: center;
  padding: 20px 0 15px;
  font-size: 30px;
  font-size: 1.875rem;
  color: #565599;
}
.form-box .section {
  padding: 20px 0;
}
.form-box .form-control {
  height: 50px;
  border-radius: 0;
}
.form-box .message {
  display: block;
  width: 100%;
  margin: 20px 0 0;
  font-size: 16px;
  text-align: right;
}
.form-box .message a {
  position: relative;
  display: inline-block;
  width: 25%;
  color: #636262;
  text-decoration: none;
  text-align: left;
  border-bottom: #ccc 1px solid;
  padding-bottom: 10px;
}
.form-box .message a::after {
  content: "→";
  display: inline-block;
  float: right;
}
.form-box.forget {
  display: none;
}
.form-box .form-list p {
  margin-bottom: 0;
}
.form-box.regis .form-group {
  display: flex;
  align-items: center;
}
.form-box.regis .form-group > label {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}
.form-box.regis .form-group .form-list {
  flex: 1;
  display: inline-flex;
  align-items: center;
}
.form-box.regis .form-group .form-list label {
  margin-bottom: 0;
}
.form-box.regis.regis-form .form-group {
  align-items: normal;
  margin-bottom: 5px;
  border: 1px solid #dee2e6;
}
.form-box.regis.regis-form .form-group > label {
  min-width: 200px;
  background: #f0f0f0;
  padding: 10px;
}
.form-box.regis.regis-form .form-group .form-list {
  padding: 5px 10px;
}
.form-box.regis.regis-form .form-group .form-list .form-control {
  margin-bottom: 5px;
}
@media (max-width: 810px) {
  .form-box.regis.regis-form .form-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .form-box.regis.regis-form .form-group > label {
    width: 100%;
    flex-direction: row !important;
    justify-content: flex-start;
    font-size: 16px;
    font-size: 1rem;
  }
  .form-box.regis.regis-form .form-group .form-list {
    width: 100%;
    flex-wrap: wrap;
  }
  .form-box.regis.regis-form .form-group .radio {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .form-box.regis.regis-form .form-group .btn {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-left: 0 !important;
  }
  .form-box.regis.regis-form .form-group .form-input .code {
    width: inherit;
  }
}
.form-status {
  margin-bottom: 1.5rem;
}
.form-status-link .icon {
  margin-top: -3px;
}

.member-content {
  padding-top: 10px;
  padding-bottom: 40px;
}
.member-content .form-box {
  max-width: 650px;
  background-color: #fff;
  border-radius: 25px;
  padding: 1.5em 3em 1.5em 2em;
  overflow: hidden;
}
.member-content-intro {
  background-color: #eee;
}
.member-content-intro-wrap {
  max-width: 600px;
}
.member-content-login {
  background-color: #fff;
}
.member-content-login .form-group {
  margin-bottom: 1.5rem;
}
.member-content-login .btn-wrap {
  margin-top: 1rem;
}
@media (max-width: 1100px) {
  .member-content-login .btn-wrap {
    flex-wrap: wrap;
  }
  .member-content-login .btn-wrap > li {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media (max-width: 1100px) {
  .member-content {
    padding: 0em;
  }
}
.member-editor .form-box {
  padding: 5px 0;
  border-radius: 0;
}
.member-editor .form-group label {
  width: 150px;
}
.member-editor .form-group > label {
  padding-left: 0;
}
.member-editor .form-group-checkbox {
  flex-direction: row;
  padding-bottom: 0;
  margin-bottom: 0;
}
.member-editor .form-group-checkbox label {
  width: 100% !important;
}
.member-editor .form-group-checkbox .radio_style {
  margin-top: 0px !important;
}
.member-editor .form-group .uploader {
  min-width: 20em;
  max-width: 100%;
  flex: 1;
}
@media (min-width: 1101px) {
  .member-editor .form-group .uploader {
    max-width: 20em;
  }
}
.member-editor .form-group .uploader-btn .btn {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
@media (max-width: 1100px) {
  .member-editor .form-group .uploader-btn {
    margin-top: 1em;
  }
}
.member-editor-buildCase-form {
  background: #eceae8;
  border-radius: 30px;
  border-top-left-radius: 0;
  padding: 2em;
}
.member-editor-buildCase-form .form-box {
  flex-wrap: wrap;
  background-color: transparent;
}
.member-editor-buildCase-form .pagination-wrap {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
@media (max-width: 1100px) {
  .member-editor-buildCase-form {
    border-top-right-radius: 0;
    padding: 0.75em 1.5em;
    margin-top: 0;
  }
}
.member-editor-buildCase-tab {
  justify-content: space-between;
}
.member-editor-buildCase-tab .nav,
.member-editor-buildCase-tab .nav li {
  position: relative;
  flex: 1;
}
.member-editor-buildCase-tab .nav-link {
  position: relative;
  z-index: 0;
  display: block;
  text-align: center;
  padding: 0.75rem 0.35rem !important;
  background-color: #e3e0de;
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
}
@media (min-width: 1101px) {
  .member-editor-buildCase-tab .nav-link {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCase-tab .nav-link {
    font-size: 18px;
    font-size: 1.125rem;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
}
.member-editor-buildCase-tab .nav li:hover > a, .member-editor-buildCase-tab .nav li.active > a {
  background-color: #eceae8;
}
.member-editor-buildCase-tab .nav::before {
  content: "";
  display: block;
  width: calc(100% - 70px);
  height: 50px;
  background-color: #e3e0de;
  position: absolute;
  top: 0px;
  left: 35px;
}
@media (min-width: 1101px) {
  .member-editor-buildCase-tab .nav {
    max-width: 68%;
  }
}
.member-editor-buildCase-tab .btn-wrap .btn {
  padding-bottom: 0.35em;
}
.member-editor-buildCase-tab .btn-wrap .btn .icon {
  margin-top: 0;
}
@media (min-width: 1101px) {
  .member-editor-buildCase-tab .btn-wrap {
    flex: 0 0 200px;
    margin: 0;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCase-tab .btn-wrap {
    order: -1;
    justify-content: flex-end;
  }
}
.member-editor-buildCase .form-group {
  margin-bottom: 0.25rem;
}
.member-editor-buildCase .icon {
  margin-top: -6px;
}
@media (max-width: 1100px) {
  .member-editor-buildCase .form-group {
    width: 100%;
    flex-direction: row !important;
  }
}
.member-editor-buildCaseAdd .form-group {
  align-items: center;
}
.member-editor-buildCaseAdd .form-group > label {
  min-width: 210px;
  line-height: 1.2;
}
@media (min-width: 1101px) {
  .member-editor-buildCaseAdd .form-group > label {
    padding-left: 1.5rem;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCaseAdd .form-group {
    align-items: flex-start;
  }
  .member-editor-buildCaseAdd .form-group.flex-column {
    flex-direction: column !important;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCaseAdd .form-box {
    margin-bottom: 10px;
  }
  .member-editor-buildCaseAdd .form-box > .form-group {
    flex-direction: column !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .member-editor-buildCaseAdd .form-box > .form-group > label {
    padding-left: 0;
    padding-bottom: 10px;
  }
  .member-editor-buildCaseAdd .form-box > .form-group .form-input-size {
    width: 100% !important;
    flex: 1;
  }
  .member-editor-buildCaseAdd .form-box .twzipcode-style {
    width: calc(100% + 30px) !important;
  }
  .member-editor-buildCaseAdd .form-lineHref {
    margin-top: 0.75em;
    flex-direction: column;
  }
  .member-editor-buildCaseAdd .form-lineHref .form-input-size,
.member-editor-buildCaseAdd .form-lineHref .unit {
    width: 100% !important;
  }
  .member-editor-buildCaseAdd .form-lineHref .unit {
    padding-left: 0;
    padding-top: 5px;
  }
}

.empty-row {
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;
  max-width: 640px;
}
.empty-row .img-box {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
}
.empty-row .img-box img {
  display: block;
  width: 100%;
}
.empty-row .content {
  margin-top: 20px;
  text-align: center;
}
.empty-row .content .title {
  color: #222;
  font-size: 32px;
  font-family: "Open Sans";
  line-height: 1.2;
  margin: 0;
}
.empty-row .content .des {
  color: #999;
  font-size: 17px;
  line-height: 1.6;
  font-family: "Open Sans";
  margin: 0;
  margin-top: 10px;
}

@media (max-width: 640px) {
  .empty-row .content {
    margin-top: 30px;
  }
  .empty-row .content .title {
    font-size: 26px;
  }
  .empty-row .content .des {
    font-size: 16px;
    margin-top: 15px;
  }
}
.error-row {
  background: url("../images/error-background.svg") no-repeat;
  background-position: center top;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
.error-row .img-box {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
}
.error-row .img-box img {
  display: block;
  width: 100%;
}
.error-row .img-box .error-code {
  color: #555;
  font-size: 120px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 1;
  margin: 0;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translateX(-57%);
}
.error-row .content {
  margin-top: 50px;
  text-align: center;
}
.error-row .content .title {
  color: #444;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}
.error-row .content .des {
  margin-top: 25px;
}
.error-row .content .des p {
  color: #555;
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
}
.error-row .content .des p:not(:last-child) {
  margin-bottom: 5px;
}
.error-row .btn-row {
  margin-top: 40px;
  margin-bottom: 20px;
}
.error-row .btn-row .link {
  border-radius: 30px;
  background: #666666;
  color: #fff;
  display: block;
  font-size: 24px;
  padding: 15px 13px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-decoration: inherit;
  text-transform: uppercase;
  transition: all 0.4s;
  width: 240px;
  line-height: 1;
}
.error-row .btn-row .link:hover {
  background: #333;
}

@media (max-width: 1440px) {
  .error-row .img-box {
    max-width: 540px;
  }
  .error-row .img-box .error-code {
    font-size: 100px;
  }
  .error-row .btn-row {
    margin-top: 50px;
  }
}
@media (max-width: 1024px) {
  .error-row .img-box {
    max-width: 440px;
  }
  .error-row .img-box .error-code {
    font-size: 70px;
    top: 45%;
  }
  .error-row .btn-row {
    margin-top: 50px;
  }
}
@media (max-width: 640px) {
  .error-row .img-box .error-code {
    font-size: 50px;
    top: 45%;
  }
  .error-row .content {
    margin-top: 30px;
  }
  .error-row .content .title {
    font-size: 26px;
  }
  .error-row .content .des {
    margin-top: 15px;
  }
  .error-row .content .des p {
    font-size: 16px;
  }
  .error-row .btn-row {
    margin-top: 30px;
  }
  .error-row .btn-row .link {
    font-size: 17px;
    max-width: 170px;
  }
}
.searchResult-wrapper {
  position: relative;
  padding: 1.5em 2em 1.5em 1.5em;
  background-color: #f8f8f8;
}
.searchResult-wrapper .searchResult-info-list {
  background-color: #fff;
  padding: 1.25em 1.2em 1em;
}
.searchResult-wrapper .searchResult-info-list:hover {
  background-color: #eee;
}
.searchResult-wrapper .searchResult-info-list:not(:last-child) {
  margin-bottom: 1em;
}
.searchResult-wrapper .searchResult-info-head {
  padding-left: 20px;
  line-height: 1.75;
}
.searchResult-wrapper .searchResult-info-headTitle {
  --min-size: 17;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #020202;
  margin-bottom: 0.35em;
}
.searchResult-wrapper .searchResult-info-headsubTitle {
  --min-size: 16;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #5d5d5e;
  line-height: 1.1;
}

.typing {
  width: 15ch;
  animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  font-size: 2em;
}

@keyframes typing {
  from {
    width: 0;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
:root {
  --fancy-slider-theme-color: #fff;
  --fancy-slider-font-family: sans-serif;
  --fancy-slider-button-side-offset: 32px;
  --fancy-slider-button-mobile-side-offset: 12px;
  --fancy-slider-button-mobile-bottom-offset: 0px;
  --fancy-slider-head-font-size-s: 36px;
  --fancy-slider-head-font-size: 48px;
  --fancy-slider-head-font-size-b: 60px;
  --fancy-slider-title-font-size: 33px;
  --fancy-slider-title-font-family: $OpenSans-300;
  --fancy-slider-title-line-height: 1;
  /* Card */
  --card-transition-duration: 500ms;
  --card-transition-delay: 250ms; /* delay background color */
  /* background */
  --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
  --fancy-slider-transition-background-delay: 1000ms; /* delay background color */
  /* other */
  --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
  --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
  --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
  /* scaling */
  --fancy-slider-transform-scale-slide: 1; /* before scale in slide - same as in js */
  --fancy-slider-transform-scale-img: 1; /* before scale in img - same as in js */
  /* nav */
  --fancy-slider-transition-button: 400ms; /*  */
  --fancy-slider-transition-button-arrow: 400ms; /*  */
  --fancy-slider-transition-button-circle: 400ms; /*  */
  --bs-aspect-ratio-mobile: 100% ;
}

@keyframes jump {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-15%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}

.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.index-content {
  padding-top: 2em;
  padding-bottom: 2em;
}
@media (max-width: 1400px) {
  .index-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1100px) {
  .index-content {
    padding: 1.5em 0.5em;
  }
}
@media (max-width: 1100px) {
  .index-pageWrapper {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}
.index-pageSwiper {
  width: 100%;
  overflow: hidden;
}
.index-pageSwiper .swiper-wrapper {
  /* 通过改变animation-timing-function 制作弹性切换效果 */
  transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}
.index-pageSwiper .swiper-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: -2em;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next {
  width: 47px;
  height: 47px;
  overflow: hidden;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev::after,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next::after {
  display: none;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev svg path,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next svg path {
  fill: #565599;
}
.index-pageSwiper .swiper-button-wrapper .swiper-button-prev.swiper-button-disabled svg path,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next.swiper-button-disabled svg path {
  fill: #bfbfbf;
}
@media (min-width: 1101px) {
  .index-pageSwiper .swiper-button-wrapper .swiper-button-prev,
.index-pageSwiper .swiper-button-wrapper .swiper-button-next {
    position: static;
  }
}
.index-pageArticle {
  line-height: 1.5;
}
.index-pageUndertake {
  background-color: #f5f5f5;
  padding: 0;
  z-index: 1;
}
@media (min-width: 1301px) and (max-width: 1710px) {
  .index-pageUndertake {
    padding-top: 3em;
    padding-bottom: 4em;
  }
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .index-pageUndertake {
    padding-top: 4em;
    padding-bottom: 5em;
  }
}
.index-pageUndertake .index-pageWrapper {
  max-width: 1800px;
  margin-left: auto;
}
@media (min-width: 1101px) {
  .index-pageUndertake .index-pageWrapper {
    padding-left: 1em;
  }
}
@media (min-width: 1100px) and (max-width: 1710px) {
  .index-pageUndertake .index-pageWrapper {
    padding-left: 3em;
  }
}
.index-pageUndertake .pageGrid-list-1to1 {
  align-items: center;
  grid-template-columns: 1.6fr 1.15fr;
}

.svgText text {
  --min-size: 72;
  --max-size: 140;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  text-transform: uppercase;
  fill: #fff;
  stroke: #565599;
  stroke-width: 1px;
  animation: animated-stroke 5s;
  text-anchor: middle;
  dominant-baseline: mathematical;
  font-family: "Poiret One", sans-serif;
}
.svgText text.textsmall {
  --min-size: 30;
  --max-size: 48;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  color: #a0a0a0;
  font-family: "Eternate";
}
@media (max-width: 1100px) {
  .svgText {
    height: 6em;
  }
  .svgText text {
    dominant-baseline: central;
  }
}

.pageAbout .pageGrid {
  align-items: center;
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .pageAbout .pageGrid {
    grid-template-columns: 0.6fr 1fr;
  }
}
@media (min-width: 1600px) {
  .pageAbout .pageAbout-img {
    width: 34.2105263158vw;
  }
}
@media (max-width: 1100px) {
  .pageAbout .pageAbout-img {
    max-width: 70%;
    margin: auto;
  }
}
.pageAbout .pageAbout-info-article .article {
  --min-size: 17;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  line-height: 1.65;
}
@media (min-width: 1101px) {
  .pageAbout .pageAbout-info-article .article {
    margin-top: 1em;
    margin-bottom: 4em;
    padding-left: 1em;
  }
}
@media (min-width: 1101px) {
  .pageAbout .pageAbout-info {
    width: 100%;
    padding-right: 1.5789473684vw;
  }
}
@media (min-width: 1600px) {
  .pageAbout .pageAbout-info {
    margin-left: -1.5789473684vw;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .pageAbout .pageAbout-info {
    padding-left: 4.2105263158vw;
    padding-right: 4.2105263158vw;
  }
}
@media (min-width: 1101px) {
  .pageAbout {
    padding-top: 5.2631578947vw;
    margin-top: -5.2631578947vw;
  }
}
@media (min-width: 1600px) {
  .pageAbout {
    padding-bottom: 0.7894736842vw;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .pageAbout {
    padding-left: 4em;
    padding-right: 4em;
  }
}
@media (max-width: 1100px) {
  .pageAbout .pageGrid {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.pageUndertake-img {
  margin-top: 2em;
}
.pageUndertake-img img {
  width: 100%;
}
@media (min-width: 1101px) {
  .pageUndertake-img {
    margin-top: -4em;
  }
}
@media (min-width: 1100px) and (max-width: 1710px) {
  .pageUndertake-img {
    margin-top: -6em;
  }
}
.pageUndertake-info-head {
  display: flex;
  align-items: flex-end;
  margin-bottom: -1.5rem;
}
.pageUndertake-info-head .img {
  margin-right: 4.7368421053vw;
  margin-left: 2.3684210526vw;
}
@media (max-width: 1500px) {
  .pageUndertake-info-head .img {
    max-width: 6em;
  }
}
@media (max-width: 1100px) {
  .pageUndertake-info-head .img {
    max-width: 7em;
  }
}
.pageUndertake-info-head .txt {
  color: #565599;
  --min-size: 25;
  --max-size: 32;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  font-family: "Noto Serif TC", serif;
  padding-bottom: 3.1578947368vw;
}
.pageUndertake-info-head-animate {
  z-index: 3;
}
@media (min-width: 1101px) {
  .pageUndertake-info-head {
    margin-top: -2.75rem;
  }
}
.pageUndertake-info-list {
  position: relative;
  padding-top: 1.5789473684vw;
  padding-bottom: 3.1578947368vw;
  padding-right: 3.1578947368vw;
}
.pageUndertake-info-listItem {
  position: relative;
  padding-top: 1em;
  cursor: pointer;
}
.pageUndertake-info-listItem .Txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pageUndertake-info-listItem .Img a {
  position: relative;
  z-index: 3;
  justify-content: center;
  width: 125px;
  height: 125px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) inset;
}
.pageUndertake-info-listItem .Img a::before, .pageUndertake-info-listItem .Img a::after {
  content: "";
  position: absolute;
  pointer-events: none;
  z-index: -1;
}
.pageUndertake-info-listItem .Img img {
  display: block;
  max-width: 85px;
  backface-visibility: hidden;
}
.pageUndertake-info-listItem h3 {
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 1em;
  color: #444444;
  font-weight: 600;
  --min-size: 16;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
@media (max-width: 1100px) {
  .pageUndertake-info-listItem h3 {
    padding-top: 5px;
  }
}
.pageUndertake-info-listItem .item {
  color: #535353;
  --min-size: 15;
  --max-size: 16;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
  padding-left: 0.5263157895vw;
  padding-right: 0.5263157895vw;
  text-align: left;
}
.pageUndertake-info-list::before, .pageUndertake-info-list::after {
  content: " ";
  position: absolute;
  left: 60px;
  right: 0;
  width: 100%;
  height: 2px;
  margin-bottom: 20px;
}
.pageUndertake-info-list::before {
  top: 115px;
  background-color: #e5e5e5;
}
.pageUndertake-info-list::after {
  top: 116px;
  background-color: #fff;
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .pageUndertake-info-list {
    padding-right: 0.2631578947vw;
  }
  .pageUndertake-info-list::before {
    top: 7rem;
  }
  .pageUndertake-info-list::after {
    top: 7.1rem;
  }
}
@media (max-width: 1100px) {
  .pageUndertake-info-list {
    padding-top: 9.375vw;
    padding-bottom: 9.375vw;
    padding-left: 4.6875vw;
  }
  .pageUndertake-info-list::before, .pageUndertake-info-list::after {
    left: 0;
    top: 8em;
  }
  .pageUndertake-info-list::after {
    top: 8.1em;
  }
}
.pageUndertake-info-list .swiper-pagination {
  bottom: 0px !important;
}
.pageUndertake-info-list .swiper-pagination-bullet {
  background: #b39654;
}

@keyframes animated-stroke {
  0% {
    stroke-dasharray: 0 80%;
  }
  100% {
    stroke-dasharray: 10% 0%;
    stroke-dashoffset: 25%;
  }
}
.titlebox {
  display: inline-block;
  padding-right: 3.6842105263vw;
  padding-top: 3.9473684211vw;
  border-right: #444444 1px solid;
}

.page-banner {
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 900px) and (max-width: 1100px) {
  .page-banner {
    margin-top: 1%;
  }
}
.page-banner::before, .page-banner::after {
  content: "";
  width: 100%;
  height: 0;
  padding-bottom: 5.3125%;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  z-index: 1;
}
.page-banner::before {
  top: -1px;
  background-image: url(../images/index-cover-top.png);
  z-index: 3;
}
@media (min-width: 1101px) {
  .page-banner {
    margin-top: -4.5vw;
  }
}
.page-banner.index {
  background: linear-gradient(to bottom, #ffffff 0%, #f7f7f7 60%, #ffffff 60%, #f7f7f7 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
@media (max-width: 1100px) {
  .page-banner.index {
    height: 46.875vw;
  }
}
.page-banner.index::after {
  bottom: -1px;
  background-image: url(../images/index-cover.png);
}
.page-banner-main {
  overflow: visible;
}
.page-banner-main::after {
  display: none;
}
@media (max-width: 640px) {
  .page-banner-main img {
    width: 100%;
    height: 125px;
    object-fit: cover;
  }
}
.page-gallerSwiper {
  overflow: hidden;
}
.page-gallerSwiper .swiper-pagination {
  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.page-gallerSwiper .swiper-pagination-bullet {
  width: 50px;
  height: 5px;
  border-radius: 0;
  margin: 0 !important;
}
.page-gallerSwiper .swiper-pagination-bullet-active {
  background-color: #565599;
}
.page-gallerSwiper .card-body {
  padding: 0;
}
.page-gallerSwiper .card-title {
  font-weight: 400;
  line-height: 1.15;
  word-wrap: normal;
}
.page-gallerSwiper .card-img-top {
  text-align: center;
}
.page-gallerSwiper .card-img-top img {
  display: inline-block;
}
.page-gallerSwiper .swiper-pagination {
  bottom: 0.5263157895vw !important;
}
@media (min-width: 760px) {
  .page-gallerSwiper .swiper-pagination-bullet {
    opacity: 0.2;
  }
  .page-gallerSwiper .swiper-pagination-bullet-active {
    opacity: 1;
  }
}
@media (max-width: 1100px) {
  .page-gallerSwiper .swiper-pagination {
    justify-content: center;
  }
}
.page-gallerSwiper-pro {
  width: 100%;
  padding-top: 0.7894736842vw;
  padding-bottom: 2.1052631579vw;
}
.page-gallerSwiper-pro .card-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .card-body {
    position: absolute;
    padding: 3em;
    color: #fff;
    background-color: rgba(49, 49, 49, 0.85);
  }
}
.page-gallerSwiper-pro .card-title {
  font-size: 42px;
  font-size: 2.625rem;
  font-family: var(--fancy-slider-title-font-family);
  border-bottom: #444444 1px solid;
  text-align: center;
  padding-bottom: 0.3em;
  margin-bottom: 0.35em;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .card-title {
    border-bottom-color: rgba(255, 255, 255, 0.65);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body {
    opacity: 0;
    transition: var(--card-transition-duration);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-title,
.page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-des {
    transition-duration: var(--card-transition-duration);
    transition-delay: var(--card-transition-delay);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-title {
    transform: translateY(-1000%);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-des {
    transform: translateY(1000%);
  }
  .page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body {
    opacity: 1;
  }
  .page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body .card-title,
.page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body .card-des {
    transform: translateY(0);
  }
}
@media (max-width: 810px) {
  .page-gallerSwiper-pro .card {
    flex-direction: row;
    align-items: flex-start;
    background-color: transparent;
  }
  .page-gallerSwiper-pro .card-img-top {
    border-radius: 20px;
    overflow: hidden;
    flex-basis: 150px;
    margin-top: 46px;
  }
  .page-gallerSwiper-pro .card-body {
    padding-left: 1.25em;
    padding-right: 0.25em;
    flex: 0 0 calc(100% - 150px);
  }
  .page-gallerSwiper-pro .card-title {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}
.page-gallerSwiper-pro .swiper-slide .card {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .swiper-slide-active .card, .page-gallerSwiper-pro .swiper-slide-next .card {
    border-bottom-right-radius: 0 !important;
  }
  .page-gallerSwiper-pro .swiper-slide-active .card {
    border-top-left-radius: 40%;
  }
  .page-gallerSwiper-pro .swiper-slide-next .card {
    border-radius: 20px;
  }
  .page-gallerSwiper-pro .swiper-slide .card {
    border-bottom-right-radius: 40%;
    overflow: hidden;
  }
}
.page-gallerSwiper-pro .swiper-act-container {
  align-items: flex-end;
  justify-content: end;
  margin-bottom: 2.75em;
  padding-right: 1em;
}
.page-gallerSwiper-pro .swiper-act-container .swiper-pagination {
  position: relative;
  width: auto;
  top: 0px;
}
.page-gallerSwiper-pro .swiper-act-container .swiper-pagination::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #cbcbcb;
  position: absolute;
  top: 25px;
  z-index: -1;
}
.page-gallerSwiper-pro .swiper-act-button {
  width: calc(3.5em + 10px);
  padding-left: 10px;
  justify-content: space-between;
}
.page-gallerSwiper-pro .swiper-act-button .swiper-button-next,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev {
  position: static;
  color: #444444;
  height: auto;
  margin-top: 0;
}
.page-gallerSwiper-pro .swiper-act-button .swiper-button-next::after,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev::after {
  font-size: 14px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction span {
  color: #b9b9b9;
  font-size: 15px;
  font-family: "Open Sans";
  padding: 0 2px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-empty {
  width: 30px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-current {
  position: relative;
  color: #444444;
  font-weight: 600;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-current::after {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  background-color: #565599;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1100px) {
  .page-gallerSwiper-pro {
    display: flex;
    flex-direction: column;
  }
  .page-gallerSwiper-pro .swiper-act-container {
    order: 1;
    margin-top: 1.1em;
  }
}
.pageGrid {
  display: grid;
  grid-column-gap: 0;
  grid-auto-rows: 0fr 0fr 0fr;
}
.pageGrid-list-frAuto {
  grid-template-columns: repeat(1, auto) 1fr;
}
.pageGrid-list-1to1 {
  grid-template-columns: 1fr 1fr;
}
.pageGrid-list-2to1 {
  grid-template-columns: 2fr 1fr 1fr;
}
.pageGrid-list-avg {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.pageGrid-list-1to2 {
  grid-template-columns: 1fr 1fr 2fr;
}
@media (max-width: 1080px) {
  .pageGrid {
    display: block;
    width: 100%;
  }
}
/* swiper index banner */
.swiper-bannerIn {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* Arrows */
}
.swiper-bannerIn .swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-bannerIn .swiper-image {
  height: 100%;
}
.swiper-bannerIn .swiper-image-inner {
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-blend-mode: overlay;
}
.swiper-bannerIn .swiper-slide {
  transition: all 1s linear;
  transition-delay: 0.5s;
  opacity: 0;
  overflow: hidden;
}
.swiper-bannerIn .swiper-slide.swiper-slide-next {
  opacity: 0;
}
.swiper-bannerIn .swiper-slide.swiper-slide-active {
  opacity: 1;
  width: 100% !important;
}
.swiper-bannerIn .swiper-slide.swiper-slide-active .swiper-image {
  overflow: hidden;
}
.swiper-bannerIn .swiper-image.swiper-image-active img {
  width: 100%;
  height: 730px;
  object-fit: cover;
}
@media (min-width: 1100px) and (max-width: 1650px) {
  .swiper-bannerIn .swiper-image.swiper-image-active img {
    height: 35em;
  }
}
.swiper-bannerIn .swiper-image-inner.swiper-image-active {
  transition: all 1s linear;
  transition-delay: 1s;
  background-blend-mode: multiply;
}
.swiper-bannerIn .swiper-button-prev,
.swiper-bannerIn .swiper-button-next {
  width: 39px;
  transition: var(--fancy-slider-transition-button);
  outline: none;
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
}
.swiper-bannerIn .swiper-button-prev::after,
.swiper-bannerIn .swiper-button-next::after {
  display: none;
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-button-prev,
.swiper-bannerIn .swiper-button-next {
    width: 40px;
    opacity: 0.75;
  }
  .swiper-bannerIn .swiper-button-prev svg image,
.swiper-bannerIn .swiper-button-next svg image {
    height: 45px;
  }
}
.swiper-bannerIn .swiper-button-prev svg,
.swiper-bannerIn .swiper-button-next svg {
  display: block;
  transition: var(--fancy-slider-transition-button);
}
.swiper-bannerIn .swiper-button-prev {
  left: 0;
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-button-prev {
    left: -2%;
  }
}
.swiper-bannerIn .swiper-button-next {
  right: 0;
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-button-next {
    right: -2%;
  }
}
.swiper-bannerIn .swiper-pagination {
  width: 100%;
  position: absolute;
  text-align: center;
  transition: 300ms;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-bannerIn .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background: #fff;
  opacity: 0.4;
}
.swiper-bannerIn .swiper-pagination-bullet-active {
  opacity: 1;
  background: #fff;
}

.pageUndertake-info-list-service.pageUndertake-info-list::before, .pageUndertake-info-list-service.pageUndertake-info-list::after {
  left: 120px;
  width: calc(100% - 300px);
}
@media (max-width: 1100px) {
  .pageUndertake-info-list-service.pageUndertake-info-list {
    padding-top: 0.5em;
    padding-bottom: 0;
  }
  .pageUndertake-info-list-service.pageUndertake-info-list::before, .pageUndertake-info-list-service.pageUndertake-info-list::after {
    display: none;
    top: 0em;
    width: 2px;
    height: 100%;
    right: auto;
    left: 5em;
    background: #e7e7e7;
  }
  .pageUndertake-info-list-service.pageUndertake-info-list .pageUndertake-info-listItem {
    display: flex;
  }
  .pageUndertake-info-list-service.pageUndertake-info-list .pageUndertake-info-listItem .Img a {
    width: 90px;
    height: 90px;
  }
  .pageUndertake-info-list-service.pageUndertake-info-list .pageUndertake-info-listItem .Img img {
    max-width: 50px;
  }
  .pageUndertake-info-list-service.pageUndertake-info-list .pageUndertake-info-listItem .Txt {
    align-items: flex-start;
    padding-left: 1em;
  }
}
.pageUndertake-info-list-appoint.pageUndertake-info-list::before, .pageUndertake-info-list-appoint.pageUndertake-info-list::after {
  display: none;
}
@media (max-width: 1100px) {
  .pageUndertake-info-list-appoint.pageUndertake-info-list {
    padding-top: 0.5em;
    padding-bottom: 0;
  }
  .pageUndertake-info-list-appoint.pageUndertake-info-list .pageUndertake-info-listItem {
    display: flex;
  }
  .pageUndertake-info-list-appoint.pageUndertake-info-list .pageUndertake-info-listItem .Img a {
    width: 90px;
    height: 90px;
  }
  .pageUndertake-info-list-appoint.pageUndertake-info-list .pageUndertake-info-listItem .Img img {
    max-width: 50px;
  }
  .pageUndertake-info-list-appoint.pageUndertake-info-list .pageUndertake-info-listItem .Txt {
    align-items: flex-start;
    padding-left: 1em;
  }
}
@media (min-width: 1101px) {
  .pageUndertake-info-list-appoint-col {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.pageUndertake-info-list-appoint-col .pageUndertake-info-listItem::after {
  content: url("../images/pageAppoint-arrowNext.png");
  position: absolute;
  right: -27px;
  top: 4em;
}
@media (max-width: 1100px) {
  .pageUndertake-info-list-appoint-col .pageUndertake-info-listItem::after {
    right: 0;
    left: 1.5em;
    top: 5.5em;
    transform: rotate(90deg);
    transform-origin: left bottom;
  }
}
.pageUndertake-info-list-appoint-col .pageUndertake-info-listItem .item {
  padding-left: 0.5263157895vw;
  padding-right: 0.5263157895vw;
}
@media (max-width: 1100px) {
  .pageUndertake-info-list-appoint-col .pageUndertake-info-listItem {
    margin-bottom: 2em;
  }
}
.pageUndertake-info-list-appoint-col:last-child .pageUndertake-info-listItem::after {
  display: none;
}

.contact-info {
  font-size: 16px;
  justify-content: space-between;
}
.contact-info dt {
  color: #565599;
  line-height: 1.5;
  margin-bottom: 0.5em;
}
.contact-info dd {
  margin-bottom: 0;
}
.contact-info dl {
  display: flex;
}
@media (min-width: 1101px) {
  .contact-info dl {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    flex-direction: column;
  }
}
@media (max-width: 1100px) {
  .contact-info dl {
    margin-bottom: 0.2em;
  }
  .contact-info dl dt {
    flex: none;
    min-width: 7em;
    padding-right: 1em;
  }
}
.contact-info-list {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 1101px) {
  .contact-info-list {
    flex: auto;
    border-right: #e9e9e9 1px solid;
    padding-right: 1em;
    margin-right: 1.25em;
  }
  .contact-info-list::after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    right: -2px;
    top: 0;
  }
  .contact-info-list:last-child {
    border-right: none;
    padding-right: 0;
    margin-right: 0;
  }
  .contact-info-list:last-child::after {
    display: none;
  }
}
@media (min-width: 1101px) {
  .contact-info {
    margin-top: 2.5em;
    margin-bottom: 3em;
  }
}
.contact-map {
  margin-bottom: 2.5em;
}
@media (min-width: 1101px) {
  .contact-map .ratio-21x9 {
    --bs-aspect-ratio: 25.857%;
  }
}

@media (min-width: 1101px) {
  .charges-wrap {
    margin-top: 3.1578947368vw;
    margin-bottom: 2.1052631579vw;
  }
}

.accordion-card .btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  font-size: 1rem;
  color: #000 !important;
  background-color: #fff !important;
  text-align: left;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
.accordion-card .btn::after {
  content: "";
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.accordion-card-flex {
  padding: 10px;
  --min-size: 18;
  --max-size: 18;
  --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
  font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
}
.accordion-card-num {
  font-family: "Open Sans", Arial;
  display: flex;
  align-items: center;
}
.accordion-card-num::after {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #565599;
  border-radius: 50%;
  margin-left: 15px;
  margin-right: 15px;
}
.accordion-card-name {
  line-height: 1.5;
}
@media (max-width: 1100px) {
  .accordion-card {
    margin-top: 0.75em;
  }
  .accordion-card-flex {
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 0;
    padding-right: 10px;
  }
  .accordion-card-num, .accordion-card-name, .accordion-card-des {
    font-size: 16px;
  }
  .accordion-card-num::after {
    margin-left: 0.5em;
    margin-right: 0.15em;
  }
  .accordion-card-des {
    width: calc(100% - 2em);
  }
}
.accordion-button {
  background-color: #eeeeed !important;
  border-radius: 0 !important;
}
.accordion-button::after {
  background-image: none !important;
  content: "\f4fe";
  font-family: bootstrap-icons !important;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 29px;
  border-radius: 50%;
  background-color: #fff;
  border: #e5e5e5 1px solid;
  font-size: 1.25rem;
}
.accordion-button:not(.collapsed) {
  color: #000;
  border-color: transparent;
}
.accordion-button:not(.collapsed)::after {
  content: "\f2ea";
}
@media (min-width: 1101px) {
  .accordion-button {
    padding-top: 0.5263157895vw;
    padding-bottom: 0.5263157895vw;
  }
}
.accordion-body {
  background-color: #fbfbfb;
}
@media (min-width: 1101px) {
  .accordion-button, .accordion-body {
    padding-left: 1.5789473684vw;
    padding-right: 1.5789473684vw;
  }
}
.accordion-item {
  border: none;
  margin-bottom: 1.5em;
}
@media (max-width: 1100px) {
  .accordion-item {
    margin-bottom: 0.5em;
  }
}
.accordion-collapse.show {
  margin-bottom: -1.5em;
}
@media (max-width: 1100px) {
  .accordion-collapse.show {
    margin-bottom: -0.5em;
  }
}