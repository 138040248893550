.form{
    &-inMain-wrap{
        @include setMinRwd{
            align-items: flex-end;
            padding-left: 70px;
            padding-right: 70px;
        }
    }

    &-wrap{
        background-color: #fff;
        border: #eee 1px solid;
        padding: 1.5em;

        legend{
            @include fontSize(16px);
            margin-bottom: 2.5em;
        }

        .form-control {
            border-radius: 0;
            border-width: 0 0 1px 0px;
        }

        @include setMinRwd{
            padding: 50px;
        }
    }

    &-flex{
        display: flex;
        margin-bottom: 25px;
        label{
            flex: none;
            width: 80px;
        }
    }
    
    &-check {
        padding-left: 0;
    }

    &-box{
        .title {
            text-align: center;
            padding: 20px 0 15px;
            @include fontSize(30px);
            color: $colorMain;
        }
        
        .section {
            padding: 20px 0;
        }
    
        .form-control{
            height: 50px;
            @include radius(0)
        }
    
        .message {
            display: block;
            width: 100%;
            margin: 20px 0 0;
            font-size: 16px;
            text-align: right;
            a {
                position: relative;
                display: inline-block;
                width: 25%;
                color: #636262;
                text-decoration: none;
                text-align: left;
                border-bottom: #ccc 1px solid;
                padding-bottom: 10px;
                &::after {
                    content: '→';
                    display: inline-block;
                    float: right;
                }
            }
        }
    
        // 
        &.forget{
            display: none;
        }
    
        .form-list{
            p{
                margin-bottom: 0;
            }
        }
    
        // 
        &.regis{
            .form-group{
                display: flex;
                align-items: center;
                &>label{
                    flex-wrap: wrap;
                    justify-content: center;
                    margin: 0;
                }
                .form-list{
                    flex: 1;
                    display: inline-flex;
                    align-items: center;
                    label{
                        margin-bottom: 0;
                    }
                }
            }
    
            &.regis-form{
                .form-group{
                    align-items: normal;
                    margin-bottom: 5px;
                    border: 1px solid #dee2e6;
                    &>label{                
                        min-width: 200px;
                        background: #f0f0f0;
                        padding: 10px;
                    }
                    .form-list{
                        padding:5px 10px;
                        .form-control{
                            margin-bottom: 5px;
                        }
                    }
                    @include pad{
                        flex-direction: column;
                        align-items: flex-start;
    
                        &>label{
                            width: 100%;
                            flex-direction: row !important;
                            justify-content: flex-start;
                            @include fontSize(16px)
                        }
    
                        .form-list{
                            width: 100%;
                            flex-wrap: wrap;
                        }
    
                        .radio{
                            margin-top: 5px;
                            margin-bottom: 5px;
                        }
    
                        .btn{
                            margin-top: 0.25rem;
                            margin-bottom: 0.25rem;
                            margin-left: 0 !important;
                        }
                        .form-input{
                            .code{
                                width: inherit;
                            }
                        }
                    }
                }
            }
        }
    }

    &-status{
        margin-bottom: 1.5rem;
        &-link{
            .icon{
                margin-top: -3px;
            }
        }
    }
}

// 會員用
.member{
    &-content{
        // padding:80px;
        padding-top: 10px;
        padding-bottom: 40px;
        .form-box{
            max-width: 650px;
            background-color: #fff;
            @include radius(25px);
            @extend .boxShadow;
            padding: 1.5em 3em 1.5em 2em;
            overflow: hidden;
        }

        // 首頁登入用
        &-intro{
            background-color: #eee;
            &-wrap{
                max-width: 600px;
            }
        }

        &-login{
            background-color: #fff;
            .form-group {
                margin-bottom: 1.5rem;
            }

            .btn-wrap{
                margin-top: 1rem;
                @include setMaxRwd{
                    flex-wrap: wrap;
                    &>li{
                        margin-left: 0;
                        margin-top: 10px;
                    }
                }
            }
        }
        // END 首頁登入用

        @include setMaxRwd{
            padding: 0em;
        }
    }

    &-editor{
        .form{
            &-box{
                padding: 5px 0;
                @include radius(0);
            }

            &-group{
                label{
                    width: 150px;
                }

                &>label{
                    padding-left: 0;
                }
    
                &-checkbox{
                    flex-direction: row;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    label{
                        width: 100% !important;
                    }

                    .radio_style {
                        margin-top: 0px !important;
                    }
                }

                .uploader {
                    min-width: 20em;
                    max-width: 100%;

                    @include setMinRwd{
                        max-width: 20em;
                    }

                    flex: 1;

                    &-btn{
                        .btn{
                            padding-top: 0.5em;
                            padding-bottom: 0.5em;
                        }

                        @include setMaxRwd{
                            margin-top: 1em;
                        }
                    }
                }
            }
        }

        &-buildCase{
            &-form{
                background: #eceae8;
                @include radius(30px);
                border-top-left-radius: 0;
                padding: 2em;

                .form-box{
                    flex-wrap: wrap;
                    background-color: transparent;
                }

                .pagination-wrap{
                    margin-top: 1.5em;
                    margin-bottom: 1.5em;
                }

                @include setMaxRwd{
                    border-top-right-radius: 0;
                    padding: 0.75em 1.5em;
                    margin-top: 0;
                }
            }

            &-tab{
                @extend %setFlex-center;
                justify-content: space-between;
                .nav{
                    &,
                    li{
                        position: relative;
                        flex: 1;
                    }

                    &-link{
                        position: relative;
                        z-index: 0;
                        display: block;
                        text-align: center;
                        padding: 0.75rem 0.35rem !important;
                        background-color: #e3e0de;
                        border-top-left-radius: 40px !important;
                        border-top-right-radius: 40px !important;

                        @include setMinRwd{
                            padding-left: 1.5rem !important;
                            padding-right: 1.5rem !important;
                        }

                        @include setMaxRwd{
                            @include fontSize(18px);
                            border-top-left-radius: 10px !important;
                            border-top-right-radius: 10px !important;
                        }
                    }

                    li{
                        &:hover,
                        &.active{
                            &>a{
                                background-color: #eceae8;
                            }
                        }
                    }

                    &::before{
                        content: "";
                        display: block;
                        @include setSize(calc(100% - 70px),50px);
                        background-color: #e3e0de;
                        position: absolute;
                        top: 0px;
                        left: 35px;
                    }

                    @include setMinRwd{
                        max-width: 68%;
                    }
                }

                .btn-wrap{
                    .btn{
                        @extend %setFlex-center;
                        padding-bottom: 0.35em;
                        .icon{
                            margin-top: 0;
                        }
                    }

                    @include setMinRwd{
                        flex: 0 0 200px;
                        margin: 0;
                    }
                }

                @include setMaxRwd{
                    .btn-wrap{
                        order:-1;
                        justify-content: flex-end;
                    }
                }
            }

            .form-group{
                margin-bottom: 0.25rem;
            }

            .icon {
                margin-top: -6px;
            }

            @include setMaxRwd{
                .form-group{
                    width: 100%;
                    flex-direction: row !important;
                }
            }

            // 新增
            &Add{
                .form-group {
                    align-items: center;
                    & > label {
                        min-width: 210px;
                        line-height: 1.2;
                    }

                    @include setMinRwd{
                        & > label {
                            padding-left: 1.5rem;
                        }
                    }

                    @include setMaxRwd{
                        align-items: flex-start;
                        &.flex-column{
                            flex-direction: column !important;
                        }
                    }
                }

                @include setMaxRwd{
                    .form-box{
                        margin-bottom: 10px;
                        & > .form-group{
                            flex-direction: column !important;
                            margin-bottom: 0;
                            padding-bottom: 0;
                            & > label{
                                padding-left: 0;
                                padding-bottom: 10px;
                            }

                            .form-input-size{
                                width: 100% !important;
                                flex: 1;
                            }
                        }

                        .twzipcode-style{
                            width: calc(100% + 30px ) !important;
                        }
                    }

                    .form-lineHref{
                        margin-top: 0.75em;
                        flex-direction: column;
                        .form-input-size,
                        .unit{
                            width: 100% !important;
                        }

                        .unit{
                            padding-left: 0;
                            padding-top: 5px;
                        }
                    }
                }
            }
        }
    }
}