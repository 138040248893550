@mixin foot-flexSB{
  display: flex;
  justify-content: space-between;
}

footer {
  padding: 1em;
  background-color: $colorMain;

  &,a{
    color: #fff;
  }

  .tag-item {
    font-size: 15px;
    background-color: #161616;
    color: #bfbfbf;
    padding: 5px 5px 6px;
  }

  .footer {
    &-wrapper{
      @include setMinRwd{
        @include foot-flexSB;
        align-items: center;
      }
    }
    
    &-info{
      p{
        margin-bottom: 10px;
      }
      
      .list-group-item{
        background-color: transparent;
        color: $colorSecondary;
        padding-left: 0;
        padding-bottom: 0;
        border: none
      }

      .copyright{
        @include fontSize(15.5px);
        @include setMaxRwd{
          @include fontSize(12px);
        }
      }

      &-logo{
        padding-bottom: 0.65em;
        & ~ p{
          font-size: 17px;
          @include setMaxRwd{
            font-size: 1rem;
            text-align: center;
          }
        }
      }

      &-left{
        flex: 1;
        @extend %setFlex;

        @include setMaxRwd{
          flex-direction: column;
        }
      }

      &-right{
        text-align: right;
        padding-top: 1.75em;

        &-row{
          @extend %setFlex;
          .item{
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            margin-bottom: 0.5rem;
            border-right: #fff 1px solid;
            &:last-child{
              border-right: none;
              padding-right: 0;
            }
            a{
              display: block;
              line-height: 1;
            }
          }
        }

        .iware{
          background: transparent;
        }

        @include setMaxRwd{
          text-align: center;
          padding-top: 0.5em;
          ul.footer-info-right-row{
            margin-bottom: 0.5rem;
            justify-content: center;
            .item{
              margin-bottom: 0.75rem;
            }
          }

          p{
            font-size: 13px !important;
          }
        }
      }
    }

    &-link{
    }
  }

  @include setMinRwd{
    padding: 20px 30px 25px;
  }

  @include setMaxRwd{
    padding-bottom: 6em;
  }
}

// 給予區塊的左右側線條使用
.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
  @include setWidthRwd(800px,1000px){
    display: none;
  }
}

.iware {
  display: inline-block;
  background: #161616;
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 4px 8px;
  &, a{
    color: #bfbfbf;
  }
  
  a {
      &:hover {
          text-decoration: underline;
      }
  }

  b{
    font-weight: 100;
  }
}

// Gotop 使用
.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
  .link-btn {
    a {
      &:hover {
          opacity: 0.65;
      }
    }

    &.nabar-icon-mobile{
      @include setMinRwd{
        display: none;
      }
      img{
        max-width: 100%;
      }
    }

    &.social{
      display: flex;
      color: #fff;

      @include setMinRwd{
        flex-direction: column;
        border-bottom: #fff 1px solid;
        padding-bottom: 10px;
        margin-bottom: 5px;
        a{
          font-size: 1.2rem;
          line-height: 2;
        }
      }

      @include setMaxRwd{
        flex: 1;
        justify-content: space-around;
        align-items: center;
        padding: 0;
      }
    }

    .social{
      &-icon{
        margin-top: 5px;
        margin-bottom: 5px;

        // For mobile
        &-mobile{
          flex: 1;
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 7.5px;
          padding-bottom: 2.5px;
          font-size: 0.975em;
          &-contact{
  
          }
  
          &-line{
            @include setMaxRwd{
              background-color: #4CAF50;
            }
          }

          @include setMaxRwd{
            svg{
              image{
                width: 33px;
              }
            }
          }
        }
      }

      &-txt{
        @include setMinRwd{
          writing-mode: vertical-lr;
          margin-top: 5px;
          letter-spacing: 3px;
        }
      }
    }

    &.top{
      display: flex;
      justify-content: center;
      color: #fff;
      a{
        line-height: 1.3;
      }

      @include setMaxRwd{
        width: 80px;
        a{
          line-height: 1.15;
        }
      }
    }
  }

  background: #504f97;
  padding: 8px 12px;
  &.active {
      right: 0px;
  }

  @include setStyleRwd(1600px){
    &.active {
        right: 10px;
    }
    
  }

  @include setMaxRwd{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    padding: 0;
    margin: 0;
    .link-btn {
      text-align: center;
      padding: 6px;

      .link-btn-toggle{
        color: #fff;
      }

      .icon{
        margin-left: 0;
        small{
          font-size: 100%;
          padding-right: 5px;
        }
      }
    }
  }
}

// Cookie Privacy
.cookies {
  display: none !important;
  width: 100%;
  background: #303030;
  color: #fff;
  @include fontSize(16px);
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 60;
  
  p {
    font-weight: 400;
    text-align: center;
    line-height: 2;

    @include setMinRwd{
      text-align: left;
      margin-bottom: 0;
    }
  }

  .btn {
    text-decoration: none !important;
    border-radius: 10px !important;

    @include setStyleMinRwd(800px){
      padding-top: 0.125rem;
      padding-bottom: 0.125rem;
    }

    @include mobile{
      display: block;
      width: 100%;
      @include fontSize(16px);
    }
  }

  &-wrapper{
    @extend %setFlex;
    flex-direction: column;
    align-items: center;

    @include setMinRwd{
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: center;
    }
  }
  
  &.display {
    display: block;
    animation: cookies 1s 1;
  }

  &.close-cookies {
    transition: all 0.2s ease-out;
    transform: translate3d(0, 100%, 0);
    @include pad {
      bottom: -2%;
    }
  }

  @include setMinRwd{
    .btn {
      @include fontSize(18px);
      margin-left: 2.5em;
      padding: 0.5rem 2.5rem;
    }
  }

  @include pad {
    width: calc(100% - 1em);
    border-top: none;
    margin: .5em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include fontSize(14px);
    
    &.display { 
      flex-direction: column;
    }
  }
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}