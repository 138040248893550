.pageUndertake-info-list{
    &-service{
        &.pageUndertake-info-list{
            &::before,
            &::after{
                left: 120px;
                width: calc(100% - 300px);
            }
    
            @include setMaxRwd{
                &::before,
                &::after{
                    display: none;
                    top: 0em;
                    width: 2px;
                    // height: calc(100% - 7.5em);
                    height: 100%;
                    right: auto;
                    left: 5em;
                    background: #e7e7e7;
                }
    
                padding-top: 0.5em;
                padding-bottom: 0;
    
                .pageUndertake-info-listItem {
                    display: flex;
                    .Img{
                        a{
                            @include setSize(90px,90px);
                        }
    
                        img{
                            max-width: 50px;
                        }
                    }
                    .Txt{
                        align-items: flex-start;
                        padding-left: 1em;
                    }
                }
            }
        }
    }

    &-appoint{
        &.pageUndertake-info-list{
            &::before,
            &::after{
                display: none;
            }
    
            @include setMaxRwd{
                padding-top: 0.5em;
                padding-bottom: 0;
    
                .pageUndertake-info-listItem {
                    display: flex;
                    .Img{
                        a{
                            @include setSize(90px,90px);
                        }
    
                        img{
                            max-width: 50px;
                        }
                    }
                    .Txt{
                        align-items: flex-start;
                        padding-left: 1em;
                    }
                }
            }
        }
        
        &-col{
            @include setMinRwd{
                flex: 0 0 calc(100% / 5);
                max-width: calc(100% / 5);
            }

            .pageUndertake-info-listItem{
                &::after{
                    content: url("../images/pageAppoint-arrowNext.png");
                    position: absolute;
                    right: -27px;
                    top: 4em;

                    @include setMaxRwd{
                        right: 0;
                        left: 1.5em;
                        top: 5.5em;
                        transform: rotate(90deg);
                        transform-origin: left bottom;
                    }
                }

                .item{
                    padding-left: get-vw(10px);
                    padding-right: get-vw(10px);
                }

                @include setMaxRwd{
                    margin-bottom: 2em;
                }
            }

            &:last-child{
                .pageUndertake-info-listItem{
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
}

// 聯絡我們
.contact{
    &-info{
        font-size: 16px;

        dt{
            color: $colorMain;
            line-height: 1.5;
            margin-bottom: 0.5em;
        }

        dd{
            margin-bottom: 0;
        }

        dl{
            display: flex;
            @include setMinRwd{
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                flex-direction: column;
            }

            @include setMaxRwd{
                margin-bottom: 0.2em;
                dt{
                    flex: none;
                    min-width: 7em;
                    padding-right: 1em;
                }
            }
        }

        @extend %setFlex;
        justify-content: space-between;
        &-list{
            position: relative;
            flex: 0 0 100%;
            max-width: 100%;

            @include setMinRwd{
                flex: auto;
                border-right: #e9e9e9 1px solid;
                padding-right: 1em;
                margin-right: 1.25em;
                &::after{
                    content: '';
                    display: block;
                    width: 1px;
                    height: 100%;
                    background-color: #fff;
                    position: absolute;
                    right: -2px;
                    top: 0;
                }
                &:last-child{
                    border-right: none;
                    padding-right: 0;
                    margin-right: 0;
                    &::after{
                        display: none;
                    }
                }
            }
        }

        @include setMinRwd{
            margin-top: 2.5em;
            margin-bottom: 3em;
        }
    }

    &-map{
        margin-bottom: 2.5em;
        .ratio-21x9 {
            @include setMinRwd{
                --bs-aspect-ratio: 25.857%;
            }
        }
    }
}

// 案件分享
.charges-wrap{
    @include setMinRwd{
        margin-top: get-vw(60px);
        margin-bottom: get-vw(40px);
    }
}

// 收合
.accordion{
    &-card{
        .btn{
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0;
            font-size: 1rem;
            color: #000 !important;
            background-color: #fff !important;
            text-align: left;
            border: 0;
            border-radius: 0;
            overflow-anchor: none;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
            &::after {
                content: "";
                flex-shrink: 0;
                width: 1.25rem;
                height: 1.25rem;
                margin-left: auto;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                background-repeat: no-repeat;
                background-size: 1.25rem;
                transition: transform .2s ease-in-out;
            }
        }
    
        &-flex{
            @extend %setFlex-center;
            padding: 10px;
            
            --min-size: 18;
            --max-size: 18;
            --font-size: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100cqw - 320px) / (1920 - 320)));
            font-size: clamp(var(--min-size) * 1px, var(--font-size), var(--max-size) * 1px);
            @extend .fw-400
        }

        &-num{
            font-family: $OpenSans-300,Arial;
            display: flex;
            align-items: center;
            &::after{
                content: '';
                display: inline-block;
                @include setSize(5px,5px);
                background-color: $colorMain;
                @include radius(50%);
                margin-left: 15px;
                margin-right: 15px;
            }
        }

        &-name{
            line-height: 1.5;
        }
        
        @include setMaxRwd{
            margin-top: 0.75em;

            &-flex{ 
                flex-wrap: nowrap;
                align-items: flex-start;
                padding: 0;
                padding-right: 10px;
            }

            &-num,
            &-name,
            &-des{
                font-size: 16px;
            }

            &-num{
                &::after{
                    margin-left: 0.5em;
                    margin-right: 0.15em;
                }
            }

            &-des{
                width: calc(100% - 2em);
            }
        }
    }

    // 原來樣式
    &-button{
        background-color: #eeeeed !important;
        border-radius: 0 !important;
        &::after{
            background-image: none !important;
            content: '\F4FE';
            font-family: bootstrap-icons !important;
            @include setSize(30px,30px);
            text-align: center;
            line-height: 29px;
            @include radius(50%);
            background-color: #fff;
            border: #e5e5e5 1px solid;
            font-size: 1.25rem;
        }

        &:not(.collapsed){
            color: #000;
            border-color: transparent;
            &::after{
                content: '\F2EA'; 
            }
        }


        @include setMinRwd{
            padding-top: get-vw(10px);
            padding-bottom: get-vw(10px);
        }
    }

    &-body{
        background-color: #fbfbfb;
    }

    &-button,
    &-body{
        @include setMinRwd{
            padding-left: get-vw(30px);
            padding-right: get-vw(30px);
        }
    }

    &-item{
        border:none;
        margin-bottom: 1.5em;
        @include setMaxRwd{
            margin-bottom: 0.5em;
        }
    }

    &-collapse{
        &.show{
            margin-bottom: -1.5em;
            @include setMaxRwd{
                margin-bottom: -0.5em;
            }
        }
    }
}